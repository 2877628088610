import { useStore } from 'vuex';

export default function useFittingComposable()
{
    const store = useStore();

    // ------------------------------------------------------------ METHODS

    /**
     * Get the letter for a fitting, such as "Lens A", "Lens B", etc.
     *
     * @param {number} id
     * @returns {string} A, B, C, etc.
     */
    function getLensLetter(id)
    {
        if(id <= 0)
        {
            return `${id}`;
        }

        return String.fromCharCode(64 + id);
    }

    /**
     * Toggle whether a fitting is selected for drawing.
     *
     * @param {"right"|"left"} side
     * @param {number?} id Defaults to the current one if `null`.
     */
    function toggleDrawing(side, id = null)
    {
        const isSelected = store.state.lensOrder.fittings.draw[side].includes(id);
        if(isSelected)
        {
            store.dispatch('lensOrder/unselectForDrawing', { side, id });
        }
        else
        {
            store.dispatch('lensOrder/selectForDrawing', { side, id });
        }
    }


    // ------------------------------------------------------------ EXPORT

    return {
        getLensLetter,
        toggleDrawing,
    };
};
