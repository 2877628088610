import { merge } from 'lodash-es';
import axios from '@/axios';
import constants from '@/constants/constants';
import UnknownPrototypeError from '@/errors/UnknownPrototypeError';
import FormPrototype from './FormPrototype';
import Order from './Order';
import BasketLine from './BasketLine';

/**
 * @class
 */
export default class Fitting
{
    // ------------------------------------------------------------ CLASS MEMBERS

    /**
     * @type {Object} Main Vuex store instance.
     */
    store = null;

    /**
     * @type {Object} Related entity.
     */
    entity = {};

    /**
     * @type {?string} Related order reference, if any.
     */
    reference = null;

    /**
     * @type {?string} Related basket line ID, if any.
     */
    basket_line_id = null;

    /**
     * @type {FormPrototype} Related prototype.
     */
    prototype = null;

    /**
     * @type {FormPrototype} Related Online Fitting Guide, if any.
     */
    ofg = null;

    /**
     * @type {boolean} Is the related OFG open?
     */
    ofgOpen = false;

    /**
     * @type {Object} Related article (available after pricing).
     */
    article = null;

    /**
     * @type {Object} Related supplements (available after pricing).
     */
    supplements = [];

    /**
     * @type {?string} Previous order reference (in case of re-order).
     */
    previous_order_reference = null;

    /**
     * @type {?string} Currency code (edit order only).
     */
    currency_code = null;

    /**
     * Customer price (edit order only).
     * Since there is a function "price", we
     * use "customer_price" here.
     * @var {string}
     */
    customer_price = null;

    /**
     * @type {?string} Special price (edit order only).
     */
    special_price = null;

    /**
     * @type {boolean} Whether currently pricing.
     */
    pricing = false;


    // ------------------------------------------------------------ CONSTRUCTOR

    /**
     * Instantiate a new Fitting.
     *
     * @param {Object} store The result of `useStore()`, imported from 'vuex'.
     *                       Must be called from the context of a Vue component.
     *                       Alternatively, `this.$store` works too, if that's
     *                       available.
     */
    constructor(store)
    {
        this.store = store;
        this.entity = this.store.state.account.cEntity;
    }


    // ------------------------------------------------------------ CLONE

    clone()
    {
        const cloned = new Fitting(this.store);

        // Set prototype
        const prototypeCode = this.prototype?.code;
        const prototype = this.store.getters['prototypes/getByCode'](prototypeCode);
        if(!prototype)
        {
            throw new UnknownPrototypeError(prototypeCode);
        }
        cloned.setPrototype(prototype);

        // Copy parameter values
        cloned.getPrototype().setParameters(this.prototype.getParameters());

        // Copy previous values, if any
        if(this.prototype.hasPreviousValues())
        {
            cloned.prototype.setPreviousValues(this.prototype.getPreviousValues());
        }

        // Set other members
        cloned.reference                = this.reference;
        cloned.basket_line_id           = this.basket_line_id;
        cloned.previous_order_reference = this.previous_order_reference;
        cloned.currency_code            = this.currency_code;
        cloned.customer_price           = this.customer_price;
        cloned.special_price            = this.special_price;

        return cloned;
    }


    // ------------------------------------------------------------ PROTOTYPE

    setPrototype(prototypeAttributes)
    {
        if(!this.prototype)
        {
            this.prototype = new FormPrototype(prototypeAttributes);
        }
        else
        {
            this.prototype.setAttributes(prototypeAttributes);
        }

        return this.prototype;
    }

    getPrototype()
    {
        return this.prototype;
    }


    // ------------------------------------------------------------ ONLINE FITTING GUIDE (OFG)

    setOfg(ofgAttributes)
    {
        this.ofg = new FormPrototype(ofgAttributes);
    }

    getOfg()
    {
        return this.ofg;
    }

    setOfgOpen(ofgOpen)
    {
        this.ofgOpen = !!ofgOpen;
    }

    getOfgOpen()
    {
        return this.ofgOpen;
    }


    // ------------------------------------------------------------ PRICING

    price(overridenValues = {})
    {
        console.log('Pricing start');

        // Set "pricing" status
        this.pricing = true;

        // Build URL
        const url = '/api/prototype/price/:eid/:prototypeCode'
            .replace(':eid', this.entity.id)
            .replace(':prototypeCode', this.prototype.code);

        // Get parameter values
        const data = {
            values:          merge({}, this.prototype.getValues(), overridenValues), // used to hack price with new values
            previous_values: this.prototype.getPreviousValues(),
        };

        // Run query
        return axios.post(url, data)
            .then(response =>
            {
                console.log('Pricing: response from CS Designer:', response.data);

                // Reset supplements array
                this.resetSupplement();
                const articleCodes = response.data;

                // Loop through returned articles and set main article and supplements
                for(const articleCode of articleCodes)
                {
                    const article = this.store.getters['catalog/getAllByCode'](articleCode)
                        .find(a => a.status !== constants.articles.status.INACTIVE);
                    if(!article)
                    {
                        // fixme: Should also reject if trying to make a new order and the status is REORDER_ONLY
                        console.log(`Article ${articleCode} is inactive for entity ${this.entity.id}`);

                        return Promise.reject(response);
                    }

                    if(article.type === constants.articles.type.SUPPLEMENT)
                    {
                        this.addSupplement(article);
                        console.log('PRICED (supplement)', articleCode, article);
                    }
                    else
                    {
                        this.setArticle(article);
                        console.log('PRICED (article)', articleCode, article);
                    }
                };

                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Pricing error', error);

                return Promise.reject(error);
            })
            .finally(() =>
            {
                this.pricing = false;
            });
    }

    setArticle(article)
    {
        this.article = article;
    }

    addSupplement(supplement)
    {
        this.supplements.push(supplement);
    }

    resetSupplement()
    {
        this.supplements = [];
    }


    // ------------------------------------------------------------ BASKET LINE

    toBasketLine()
    {
        const basketLine = new BasketLine({
            id:                       this.basket_line_id,
            article_code:             this.article.code,
            previous_order_reference: this.previous_order_reference,
            order_by:                 this.entity.id,
            delivery_to:              this.entity.id,
        });

        basketLine.supplements = this.supplements.map(supplement =>
        {
            return {
                quantity:     1,
                article_code: supplement.code,
            };
        });

        basketLine.setArticleDataAttribute('PROTOTYPE_CODE', this.getPrototype().code);

        if(this.ofg)
        {
            basketLine.setArticleDataAttribute('OFG', this.ofg.getValues());
        }

        this.prototype.getParameters().forEach((parameter, key) =>
        {
            const value = parameter.getValue();

            switch(parameter.data_section)
            {
                case 'article_data':
                    basketLine.setArticleDataAttribute(key, value);

                    // Save default value (if any)
                    const defaultValue = parameter.getDefaultValue();

                    if(defaultValue !== null)
                    {
                        basketLine.setArticleDataAttributeDefaultValue(key, defaultValue);
                    }
                    break;

                case 'logistics_data':
                    basketLine.setLogisticsDataAttribute(key, value);
                    break;

                case 'return_data':
                    basketLine.setReturnDataAttribute(key, value);
                    break;

                case 'extra':
                    basketLine.setExtraAttribute(key, value);
                    break;

                case 'NATIVE':
                    basketLine[key] = value;
                    break;
            }


        });

        console.log('Adpatation to basket line', this, basketLine);

        return basketLine;
    }


    // ------------------------------------------------------------ ORDER

    toOrder()
    {
        const order = new Order({
            reference:                this.reference,
            article:                  this.article,
            previous_order_reference: this.previous_order_reference,
            order_by:                 this.entity.id,
            delivery_to:              this.entity.id,
            special_price:            this.special_price,
        });

        order.supplements = this.supplements.map(supplement =>
        {
            return {
                quantity:     1,
                article_code: supplement.code,
            };
        });

        order.setArticleDataAttribute('PROTOTYPE_CODE', this.getPrototype().code);

        if(this.ofg)
        {
            order.setArticleDataAttribute('OFG', this.ofg.getValues());
        }

        this.getPrototype().getParameters().forEach((parameter, key) =>
        {
            const value = parameter.getValue();

            switch(parameter.data_section)
            {
                case 'article_data':
                    order.setArticleDataAttribute(key, value);
                    break;

                case 'logistics_data':
                    order.setLogisticsDataAttribute(key, value);
                    break;

                case 'return_data':
                    order.setReturnDataAttribute(key, value);
                    break;

                case 'extra':
                    order.setExtraAttribute(key, value);
                    break;

                case 'NATIVE':
                    order[key] = value;
                    break;
            }
        });

        console.log('Adpatation to order', this, order);

        return order;
    }
}
