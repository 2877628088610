<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <main-layout>
        <div id="sl-contact-details" class="sl-page w-4/5">
            <!-- Page Header -->
            <div class="sl-page__header">
                <div class="sl-page__title">
                    {{ $t('contact_details.page_title') }}
                </div>
            </div>

            <!-- Page Content -->
            <div class="sl-page__content text-gray-600">

                <!-- ---------------------------------------- VAT NUMBER & PERSON IN CHARGE -->

                <div class="grid grid-cols-2 gap-12">
                    <div class="">
                        <div class="sl-address__title">
                            {{ $t('common.address.general_data') }}
                        </div>

                        <div class="space-y-4">
                            <!-- VAT Number -->
                            <lsn-input
                                v-model="vat_number"
                                :error="errors.vat_number"
                                :maxlength="20"
                                :label="$t('contact_details.vat_number')"
                                class="lsn-form__wrapper--padless w-1/2"
                                disabled
                            />

                            <!-- Contact Person -->
                            <lsn-input
                                v-model="contact_person"
                                :error="errors.contact_person"
                                :maxlength="35"
                                :label="$t('contact_details.contact_person')"
                                class="lsn-form__wrapper--padless w-1/2"
                            />
                        </div>
                    </div>

                    <div>
                        <!-- Nothing. -->
                    </div>
                </div>


                <!-- ---------------------------------------- ADDRESS & CONTACT -->

                <div class="grid grid-cols-2 gap-12 items-start mt-8">
                    <!-- Shipping Address -->
                    <div class="sl-address">
                        <div class="sl-address__title">
                            {{ $t('common.address.shipping.label') }}
                        </div>

                        <div class="space-y-4">
                            <div class="flex justify-between items-start gap-1">
                                <!-- Name -->
                                <lsn-input
                                    v-model="addresses.shipping.recipient_name"
                                    :error="errors.shipping?.recipient_name"
                                    :maxlength="35"
                                    :label="$t('common.address.recipient_name')"
                                    required
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />

                                <!-- Name Complement -->
                                <lsn-input
                                    v-model="addresses.shipping.recipient_name_affix"
                                    :error="errors.shipping?.recipient_name_affix"
                                    :maxlength="35"
                                    :label="$t('common.address.recipient_name_affix')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- FAO / For the Attention Of -->
                            <div>
                                <lsn-input
                                    v-model="addresses.shipping.fao"
                                    :error="errors.shipping?.fao"
                                    :maxlength="35"
                                    :label="$t('common.address.fao')"
                                    class="lsn-form__wrapper--padless"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- Address Line 1 -->
                            <div>
                                <lsn-input
                                    v-model="addresses.shipping.line1"
                                    :error="errors.shipping?.line1"
                                    :maxlength="35"
                                    :label="$t('common.address.label')"
                                    class="lsn-form__wrapper--padless"
                                    :required="!addresses.shipping.po_box"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- Address Line 2 -->
                            <div>
                                <lsn-input
                                    v-model="addresses.shipping.line2"
                                    :error="errors.shipping?.line2"
                                    :maxlength="35"
                                    :label="$t('common.address.line2')"
                                    class="lsn-form__wrapper--padless"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- PO Box -->
                            <div class="w-1/4">
                                <lsn-input
                                    v-model="addresses.shipping.po_box"
                                    :error="errors.shipping?.po_box"
                                    :maxlength="20"
                                    :label="$t('common.address.po_box')"
                                    class="lsn-form__wrapper--padless"
                                    :required="!addresses.shipping.line1"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- Post Code -->
                                <lsn-input
                                    v-model="addresses.shipping.postcode"
                                    :error="errors.shipping?.postcode"
                                    :maxlength="8"
                                    :label="$t('common.address.post_code')"
                                    required
                                    :disabled="savingAddresses"
                                    class="lsn-form__wrapper--padless w-1/4"
                                />

                                <!-- City -->
                                <lsn-input
                                    v-model="addresses.shipping.city"
                                    :error="errors.shipping?.city"
                                    :maxlength="35"
                                    :label="$t('common.address.city')"
                                    required
                                    class="lsn-form__wrapper--padless w-3/4"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- State -->
                                <lsn-input
                                    v-model="addresses.shipping.county"
                                    :error="errors.shipping?.county"
                                    :maxlength="35"
                                    :label="$t('common.address.state')"
                                    class="lsn-form__wrapper--padless w-4/5"
                                    :disabled="savingAddresses"
                                />

                                <!-- Country -->
                                <lsn-input
                                    v-model="addresses.shipping.country"
                                    :error="errors.shipping?.country"
                                    :maxlength="2"
                                    :label="$t('common.address.country')"
                                    required
                                    class="lsn-form__wrapper--padless w-1/5"
                                    disabled
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- Phone -->
                                <lsn-input
                                    v-model="addresses.shipping.phone"
                                    :error="errors.shipping?.phone"
                                    :maxlength="20"
                                    :label="$t('common.address.phone')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />

                                <!-- Phone 2 -->
                                <lsn-input
                                    v-model="addresses.shipping.phone2"
                                    :error="errors.shipping?.phone2"
                                    :maxlength="20"
                                    :label="$t('common.address.phone') + ' 2'"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- Mobile Phone -->
                                <lsn-input
                                    v-model="addresses.shipping.mobile_phone"
                                    :error="errors.shipping?.mobile_phone"
                                    :maxlength="20"
                                    :label="$t('common.address.mobile_phone')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />

                                <!-- Fax -->
                                <lsn-input
                                    v-model="addresses.shipping.fax"
                                    :error="errors.shipping?.fax"
                                    :maxlength="20"
                                    :label="$t('common.address.fax')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- Email -->
                            <div>
                                <lsn-input
                                    v-model="addresses.shipping.email"
                                    :error="errors.shipping?.email"
                                    :maxlength="80"
                                    :label="$t('common.address.email')"
                                    class="lsn-form__wrapper--padless"
                                    :disabled="savingAddresses"
                                />
                            </div>
                        </div>

                        <!-- Save Button (buying group only) -->
                        <div v-if="!canEditBillingAddress" class="flex justify-end mt-4">
                            <lsn-loader-button
                                class="lsn-btn--primary"
                                :is-loading="savingAddresses"
                                :label="$t('common.save')"
                                @click="saveAddresses"
                            />
                        </div>
                    </div>

                    <!-- Billing Address -->
                    <div v-if="canEditBillingAddress" class="sl-address">
                        <div class="sl-address__title">
                            <div>
                                {{ $t('common.address.billing.label') }}
                            </div>

                            <div>
                                <lsn-switch
                                    v-model="sameAddresses"
                                    class="lsn-form__wrapper--padless text-sm"
                                    :label="$t('common.address.billing.same_as_shipping')"
                                />
                            </div>
                        </div>

                        <div class="grow"></div>

                        <div v-if="!sameAddresses" class="space-y-4">
                            <div class="flex justify-between items-start gap-1">
                                <!-- Name -->
                                <lsn-input
                                    v-model="addresses.billing.recipient_name"
                                    :error="errors.billing?.recipient_name"
                                    :maxlength="35"
                                    :label="$t('common.address.recipient_name')"
                                    required
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />

                                <!-- Name Complement -->
                                <lsn-input
                                    v-model="addresses.billing.recipient_name_affix"
                                    :error="errors.billing?.recipient_name_affix"
                                    :maxlength="35"
                                    :label="$t('common.address.recipient_name_affix')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- FAO / For the Attention Of -->
                            <div>
                                <lsn-input
                                    v-model="addresses.billing.fao"
                                    :error="errors.billing?.fao"
                                    :maxlength="35"
                                    :label="$t('common.address.fao')"
                                    class="lsn-form__wrapper--padless"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- Address Line 1 -->
                            <div>
                                <lsn-input
                                    v-model="addresses.billing.line1"
                                    :error="errors.billing?.line1"
                                    :maxlength="35"
                                    :label="$t('common.address.label')"
                                    class="lsn-form__wrapper--padless"
                                    :required="!addresses.billing.po_box"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- Address Line 2 -->
                            <div>
                                <lsn-input
                                    v-model="addresses.billing.line2"
                                    :error="errors.billing?.line2"
                                    :maxlength="35"
                                    :label="$t('common.address.line2')"
                                    class="lsn-form__wrapper--padless"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- PO Box -->
                            <div class="w-1/4">
                                <lsn-input
                                    v-model="addresses.billing.po_box"
                                    :error="errors.billing?.po_box"
                                    :maxlength="20"
                                    :label="$t('common.address.po_box')"
                                    class="lsn-form__wrapper--padless"
                                    :required="!addresses.billing.line1"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- Post Code -->
                                <lsn-input
                                    v-model="addresses.billing.postcode"
                                    :error="errors.billing?.postcode"
                                    :maxlength="8"
                                    :label="$t('common.address.post_code')"
                                    required
                                    class="lsn-form__wrapper--padless w-1/4"
                                    :disabled="savingAddresses"
                                />

                                <!-- City -->
                                <lsn-input
                                    v-model="addresses.billing.city"
                                    :error="errors.billing?.city"
                                    :maxlength="35"
                                    :label="$t('common.address.city')"
                                    required
                                    class="lsn-form__wrapper--padless w-3/4"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- State -->
                                <lsn-input
                                    v-model="addresses.billing.county"
                                    :error="errors.billing?.county"
                                    :maxlength="35"
                                    :label="$t('common.address.state')"
                                    class="lsn-form__wrapper--padless w-4/5"
                                    :disabled="savingAddresses"
                                />

                                <!-- Country -->
                                <lsn-input
                                    v-model="addresses.billing.country"
                                    :error="errors.billing?.country"
                                    :maxlength="2"
                                    :label="$t('common.address.country')"
                                    required
                                    class="lsn-form__wrapper--padless w-1/5"
                                    disabled
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- Phone -->
                                <lsn-input
                                    v-model="addresses.billing.phone"
                                    :error="errors.billing?.phone"
                                    :maxlength="20"
                                    :label="$t('common.address.phone')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />

                                <!-- Phone 2 -->
                                <lsn-input
                                    v-model="addresses.billing.phone2"
                                    :error="errors.billing?.phone2"
                                    :maxlength="20"
                                    :label="$t('common.address.phone') + ' 2'"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <div class="flex justify-between items-start gap-1">
                                <!-- Mobile Phone -->
                                <lsn-input
                                    v-model="addresses.billing.mobile_phone"
                                    :error="errors.billing?.mobile_phone"
                                    :maxlength="20"
                                    :label="$t('common.address.mobile_phone')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />

                                <!-- Fax -->
                                <lsn-input
                                    v-model="addresses.billing.fax"
                                    :error="errors.billing?.fax"
                                    :maxlength="20"
                                    :label="$t('common.address.fax')"
                                    class="lsn-form__wrapper--padless w-1/2"
                                    :disabled="savingAddresses"
                                />
                            </div>

                            <!-- Email (do not display this field is billing address country is Ukraine or Moldova since they are not billed by SwissLens) -->
                            <div :class="{'invisible': ['UA','MD'].includes(addresses.billing.country)}">
                                <lsn-input
                                    v-model="addresses.billing.email"
                                    :error="errors.billing?.email"
                                    :maxlength="80"
                                    :label="$t('common.address.email')"
                                    class="lsn-form__wrapper--padless"
                                    :disabled="savingAddresses"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Form Actions -->
                <div v-if="canEditBillingAddress" class="flex justify-center mt-12">
                    <lsn-loader-button
                        class="lsn-btn--primary"
                        :is-loading="savingAddresses"
                        :label="$t('common.save')"
                        @click="saveAddresses"
                    />
                </div>


                <!-- ---------------------------------------- DOCUMENT DISTRIBUTION -->

                <div v-is-staff class="mt-8 mb-4 text-lg text-primary-500">
                    {{ $t('document_distribution.title') }}
                </div>

                <table v-is-staff class="sl-document-distribution-table">
                    <!-- Header -->
                    <thead>
                        <tr>
                            <td>
                                {{ $t('document_distribution.document_type.label') }}
                            </td>

                            <td>
                                {{ $t('common.mail') }}
                            </td>

                            <td>
                                {{ $t('common.email') }}
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <!-- Invoices -->
                        <tr v-if="!isMemberOfBuyingGroup">
                            <td>
                                {{ $t('document_distribution.invoices.label') }}
                            </td>

                            <td>
                                <lsn-switch
                                    v-model="document_distribution.invoices.mail"
                                    :disabled="savingDocumentDistribution"
                                    @update:model-value="saveDocumentDistribution"
                                />
                            </td>

                            <td>
                                <lsn-switch
                                    v-model="document_distribution.invoices.email"
                                    :disabled="savingDocumentDistribution"
                                    @update:model-value="saveDocumentDistribution"
                                />
                            </td>
                        </tr>

                        <!-- Virtual Stock Invoices -->
                        <tr v-if="false">
                            <td>
                                {{ $t('document_distribution.virtual_stock_invoices.label') }}
                            </td>

                            <td>
                                <lsn-switch
                                    v-model="document_distribution.virtual_stock_invoices.mail"
                                    :disabled="savingDocumentDistribution"
                                    @update:model-value="saveDocumentDistribution"
                                />
                            </td>

                            <td>
                                <lsn-switch
                                    v-model="document_distribution.virtual_stock_invoices.email"
                                    :disabled="savingDocumentDistribution"
                                    @update:model-value="saveDocumentDistribution"
                                />
                            </td>
                        </tr>

                        <!-- Monthly Summaries -->
                        <tr v-if="isMemberOfBuyingGroup">
                            <td>
                                {{ $t('document_distribution.monthly_summaries.label') }}
                            </td>

                            <td v-tippy :content="$t('document_distribution.monthly_summaries.no_mail')">
                                <!-- Monthly summaries are never sent by mail. (HdM - 2023-05-10) -->
                                <lsn-switch
                                    v-model="document_distribution.monthly_summaries.mail"
                                    disabled
                                />
                            </td>

                            <td>
                                <lsn-switch
                                    v-model="document_distribution.monthly_summaries.email"
                                    :disabled="savingDocumentDistribution"
                                    @update:model-value="saveDocumentDistribution"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </main-layout>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { useToast }    from 'vue-toastification';
import axios           from '@/axios';
import MainLayout      from '@/layouts/MainLayout.vue';
import LsnLoaderButton from '@/components/LsnLoaderButton.vue';

const CODE_SHIPPING = 'SHIPPING';
const CODE_BILLING  = 'BILLING';

const FN_USED_FOR_SHIPPING = x => x.used_for === CODE_SHIPPING;
const FN_USED_FOR_BILLING  = x => x.used_for === CODE_BILLING;

export default
{
    name: 'ContactDetails',

    components:
    {
        MainLayout,
        LsnLoaderButton,
    },

    data()
    {
        return {
            // ---------------------------------------- General Data

            vat_number:     '',
            contact_person: '',


            // ---------------------------------------- Addresses

            savingAddresses: false,
            sameAddresses:   true,
            addresses:
            {
                shipping:
                {
                    // Address
                    recipient_name:       null,
                    recipient_name_affix: null,
                    fao:                  null,
                    line1:                null,
                    line2:                null,
                    poBox:                null,
                    postcode:             null,
                    city:                 null,
                    county:               null,
                    country:              null,

                    // Contacts
                    phone:        null,
                    phone2:       null,
                    mobile_phone: null,
                    fax:          null,
                    email:        null,
                },
                billing:
                {
                    // Address
                    recipient_name:       null,
                    recipient_name_affix: null,
                    fao:                  null,
                    line1:                null,
                    line2:                null,
                    poBox:                null,
                    postCode:             null,
                    city:                 null,
                    county:               null,
                    country:              null,

                    // Contacts
                    phone:        null,
                    phone2:       null,
                    mobile_phone: null,
                    fax:          null,
                    email:        null,
                },
            },
            errors: {},


            // ---------------------------------------- Document Distribution

            savingDocumentDistribution: false,
            document_distribution:
            {
                invoices:
                {
                    mail:  false,
                    email: true,
                },
                virtual_stock_invoices:
                {
                    mail:  false,
                    email: true,
                },
                monthly_summaries:
                {
                    mail:  false,
                    email: true,
                },
            },
        };
    },

    computed:
    {
        // ---------------------------------------- Current Entity

        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        /**
         * Is the current entity a member of a buying group?
         * Buying group = "Centrale d’achat"
         */
        isMemberOfBuyingGroup()
        {
            return this.cEntity.member_of && !this.cEntity.payment_method;
        },

        canEditBillingAddress()
        {
            return !this.isMemberOfBuyingGroup || this.$store.getters['account/isInternalStaff'];
        },


        // ---------------------------------------- Addresses & Contacts

        cShippingAddress()
        {
            return this.cEntity.addresses.find(FN_USED_FOR_SHIPPING);
        },

        cShippingContacts()
        {
            return this.cEntity.contacts.filter(FN_USED_FOR_SHIPPING);
        },

        cBillingAddress()
        {
            return this.cEntity.addresses.find(FN_USED_FOR_BILLING);
        },

        cBillingContacts()
        {
            return this.cEntity.contacts.filter(FN_USED_FOR_BILLING);
        },


        // ---------------------------------------- Document Distribution

        documentDistributionValues()
        {
            const out = {};

            if(this.isMemberOfBuyingGroup)
            {
                // Monthly Summaries
                out['document_distribution.monthly_summaries'] = 0;
                if(this.document_distribution.monthly_summaries.mail)
                {
                    out['document_distribution.monthly_summaries'] |= 1;
                }

                if(this.document_distribution.monthly_summaries.email)
                {
                    out['document_distribution.monthly_summaries'] |= 2;
                }
            }
            else
            {
                // Invoices
                out['document_distribution.invoices'] = 0;
                if(this.document_distribution.invoices.mail)
                {
                    out['document_distribution.invoices'] |= 1;
                }

                if(this.document_distribution.invoices.email)
                {
                    out['document_distribution.invoices'] |= 2;
                }

                // Virtual Stock Invoices
                out['document_distribution.virtual_stock_invoices'] = 0;
                if(this.document_distribution.virtual_stock_invoices.mail)
                {
                    out['document_distribution.virtual_stock_invoices'] |= 1;
                }

                if(this.document_distribution.virtual_stock_invoices.email)
                {
                    out['document_distribution.virtual_stock_invoices'] |= 2;
                }
            }

            return out;
        },
    },

    watch:
    {
        sameAddresses()
        {
            // Both addresses must always have the same country
            this.addresses.billing.country = this.cShippingAddress.country;
        },
    },

    mounted()
    {
        this.loadCurrentAddresses();
        this.sameAddresses = this.areAddressesIdentical();

        this.loadCurrentDocumentDistributions();
    },

    methods:
    {
        // ---------------------------------------- Addresses & Contacts

        loadCurrentAddresses()
        {
            // VAT number
            this.vat_number = this.cEntity.vat_number;

            // Contact person
            this.contact_person = this.cEntity.contact_person;

            if(this.cShippingAddress)
            {
                // Address fields
                this.addresses.shipping.recipient_name       = this.cShippingAddress.recipient_name;
                this.addresses.shipping.recipient_name_affix = this.cShippingAddress.recipient_name_affix;
                this.addresses.shipping.fao                  = this.cShippingAddress.fao;
                this.addresses.shipping.line1                = this.cShippingAddress.line1;
                this.addresses.shipping.line2                = this.cShippingAddress.line2;
                this.addresses.shipping.po_box               = this.cShippingAddress.po_box;
                this.addresses.shipping.postcode             = this.cShippingAddress.postcode;
                this.addresses.shipping.city                 = this.cShippingAddress.city;
                this.addresses.shipping.county               = this.cShippingAddress.county;
                this.addresses.shipping.country              = this.cShippingAddress.country;

                // Contacts
                const phones = this.cShippingContacts.filter(c => c.type === 'PHONE');
                this.addresses.shipping.phone        = phones[0]?.formatted_value || null;
                this.addresses.shipping.phone2       = phones[1]?.formatted_value || null;
                this.addresses.shipping.mobile_phone = this.cShippingContacts.find(c => c.type === 'MOBILE_PHONE')?.formatted_value || null;
                this.addresses.shipping.fax          = this.cShippingContacts.find(c => c.type === 'FAX')?.formatted_value || null;
                this.addresses.shipping.email        = this.cShippingContacts.filter(c => c.type === 'EMAIL').map(c => c.cvalue).join(';') || null;
            }

            if(this.cBillingAddress)
            {
                // Address fields
                this.addresses.billing.recipient_name       = this.cBillingAddress.recipient_name;
                this.addresses.billing.recipient_name_affix = this.cBillingAddress.recipient_name_affix;
                this.addresses.billing.fao                  = this.cBillingAddress.fao;
                this.addresses.billing.line1                = this.cBillingAddress.line1;
                this.addresses.billing.line2                = this.cBillingAddress.line2;
                this.addresses.billing.po_box               = this.cBillingAddress.po_box;
                this.addresses.billing.postcode             = this.cBillingAddress.postcode;
                this.addresses.billing.city                 = this.cBillingAddress.city;
                this.addresses.billing.county               = this.cBillingAddress.county;
                this.addresses.billing.country              = this.cBillingAddress.country; // usually the same country in both addresses

                // Contacts
                const phones = this.cBillingContacts.filter(c => c.type === 'PHONE');
                this.addresses.billing.phone        = phones[0]?.formatted_value || null;
                this.addresses.billing.phone2       = phones[1]?.formatted_value || null;
                this.addresses.billing.mobile_phone = this.cBillingContacts.find(c => c.type === 'MOBILE_PHONE')?.formatted_value || null;
                this.addresses.billing.fax          = this.cBillingContacts.find(c => c.type === 'FAX')?.formatted_value || null;
                this.addresses.billing.email        = this.cBillingContacts.filter(c => c.type === 'EMAIL').map(c => c.cvalue).join(';') || null;
            }
        },

        toggleSameAddresses()
        {
            this.sameAddresses = !this.sameAddresses;
        },

        saveAddresses()
        {
            if(this.savingAddresses)
            {
                return;
            }

            // Format data for web service
            const data = {
                // vat_number:     this.vat_number, // XXX: Temporarily disable saving VAT number (until ready with HdM)
                contact_person: this.contact_person,
                same_addresses: this.sameAddresses,
                shipping:       this.addresses.shipping,
            };
            if(!this.sameAddresses)
            {
                data.billing = this.addresses.billing;
            }

            this.savingAddresses = true;
            axios.post(`/api/contact-details/${this.cEntity.id}`, data)
                .then(response =>
                {
                    // Clear errors
                    this.errors = {};

                    useToast().success(this.$t('contact_details.notifications.save_success'));

                    // Update addresses & contacts in store
                    this.$store.dispatch('account/fetchCurrentEntity', this.cEntity.id)
                        .then(response =>
                        {
                            // Refresh display
                            this.loadCurrentAddresses();
                        })
                        .catch(error =>
                        {
                            useToast().error(this.$t('contact_details.notifications.refresh_error'));
                        })
                        .then(() =>
                        {
                            this.savingAddresses = false;
                        });
                })
                .catch(error =>
                {
                    useToast().error(this.$t('contact_details.notifications.save_error'));
                    this.errors = error.response.errors;
                    this.savingAddresses = false;
                });
        },

        areAddressesIdentical()
        {
            // If all fields in the billing address are empty/falsy, consider addresses identical
            if(Object.values(this.addresses.billing).every(v => !v))
            {
                return true;
            }

            // If at least one field differs between shipping and billing addresses, then they're not identical
            for(const key in this.addresses.shipping)
            {
                if(this.addresses.shipping[key] !== this.addresses.billing[key])
                {
                    return false;
                }
            }

            return true;
        },


        // ---------------------------------------- Document Distribution

        /**
         * Load the current document distribution settings into the component's data.
         */
        loadCurrentDocumentDistributions()
        {
            // Invoices
            if(typeof this.cEntity['document_distribution.invoices'] !== 'undefined')
            {
                this.document_distribution.invoices.mail  = (this.cEntity['document_distribution.invoices'] & 1) > 0;
                this.document_distribution.invoices.email = (this.cEntity['document_distribution.invoices'] & 2) > 0;
            }

            // Virtual Stock Invoices
            if(typeof this.cEntity['document_distribution.virtual_stock_invoices'] !== 'undefined')
            {
                this.document_distribution.virtual_stock_invoices.mail  = (this.cEntity['document_distribution.virtual_stock_invoices'] & 1) > 0;
                this.document_distribution.virtual_stock_invoices.email = (this.cEntity['document_distribution.virtual_stock_invoices'] & 2) > 0;
            }

            // Monthly Summaries
            if(typeof this.cEntity['document_distribution.monthly_summaries'] !== 'undefined')
            {
                this.document_distribution.monthly_summaries.mail  = (this.cEntity['document_distribution.monthly_summaries'] & 1) > 0;
                this.document_distribution.monthly_summaries.email = (this.cEntity['document_distribution.monthly_summaries'] & 2) > 0;
            }
        },

        saveDocumentDistribution()
        {
            if(this.savingDocumentDistribution)
            {
                return;
            }

            this.savingDocumentDistribution = true;
            axios.post(`/api/document-distribution/${this.cEntity.id}`, this.documentDistributionValues)
                .then(response =>
                {
                    useToast().success(this.$t('document_distribution.notifications.save_success'));
                    this.savingDocumentDistribution = false;
                })
                .catch(error =>
                {
                    useToast().error(this.$t('document_distribution.notifications.save_error'));
                    this.loadCurrentDocumentDistributions();
                    this.savingDocumentDistribution = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-page__content
{
    @apply mb-8;
}


// ---------------------------------------- Addresses & Contacts

.sl-address
{
    @apply flex flex-col justify-end;
}

.sl-address__title
{
    @apply flex justify-between items-center mb-4 text-gray-800 text-lg;
}


// ---------------------------------------- Document Distribution

.sl-document-distribution-table thead
{
    @apply font-bold;
}

.sl-document-distribution-table tr > td
{
    @apply h-12;

    min-width: 100px;
}

.sl-document-distribution-table tr > td:first-child
{
    min-width: 300px;
}

.sl-document-distribution-table tr > td:not(:first-child)
{
    @apply text-center align-middle;
}
</style>
