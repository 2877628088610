import Formatters from './Formatters';

let i18n  = null;
let store = null;

export function pf(parameterValue, parameterCode, context = null)
{
    const formattersString = store.getters['parameters/getFormatters'](parameterCode);
    if(formattersString)
    {
        const formatters = formattersString.split('|');
        for(const formatter of formatters)
        {
            const parts = formatter.split(':');
            const fn    = parts[0];
            const args  = [];

            if(parts[1])
            {
                args.push(...parts[1].split(','));
            }

            args.push({ i18n, pcode: parameterCode, context });
            args.unshift(parameterValue);

            parameterValue = Formatters[fn].apply(null, args);
        }
    }

    return parameterValue;
};

export function f(val, formattersString)
{
    const formatters = formattersString.split('|');
    for(const formatter of formatters)
    {
        const parts = formatter.split(':');
        const fn    = parts[0];
        const args  = [];

        if(parts[1])
        {
            args.push(...parts[1].split(','));
        }

        args.push({ i18n });
        args.unshift(val);

        val = Formatters[fn].apply(null, args);
    }

    return val;
};

export default
{
    install(app, options = { i18n: null, store: null })
    {
        if(!options.i18n)
        {
            throw new Error('vue-formatter: missing i18n parameter');
        }

        if(!options.store)
        {
            throw new Error('vue-formatter: missing store parameter');
        }

        i18n = options.i18n;
        store = options.store;

        app.config.globalProperties.$pf = pf;
        app.config.globalProperties.$f  = f;
    },
};
