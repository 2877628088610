// ------------------------------------------------------------ IMPORTS

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { f } from '@/utils/vue-formatter';
import Numbers from '@/utils/Numbers';

export default function useOrderTableComposable(props)
{
    // ------------------------------------------------------------ COMPOSABLES

    const { t } = useI18n({ useScope: 'global' });
    const store = useStore();


    // ------------------------------------------------------------ CONSTANTS

    const debounceDelay = 1000;


    // ------------------------------------------------------------ COMPUTED

    /**
     * The quantity of an order line.
     *
     * @returns {Number}
     */
    const quantity = computed(() =>
    {
        return parseInt(props.order.quantity);
    });

    /**
     * The calculated price for an order line, including supplements (if any).
     *
     * @returns {Number}
     */
    const price = computed(() =>
    {
        const supplements = props.order.supplements || [];

        return quantity.value * supplements.reduce(
            (carry, supplement) => carry + (supplement.quantity * supplement.price),
            props.order.price
        );
    });

    /**
     * The base price for an order line, or NaN if that data is unavailable.
     *
     * @returns {Number}
     */
    const basePrice = computed(() =>
    {
        const basePrice = parseFloat(props.order.catalog_price);
        if(isNaN(basePrice))
        {
            return basePrice;
        }

        const supplements = props.order.supplements || [];

        return quantity.value * supplements.reduce(
            (carry, supplement) => carry + (supplement.quantity * supplement.price),
            basePrice
        );
    });

    /**
     * The base price, formatted with 2 decimals and thousand separators.
     *
     * @returns {String}
     */
    const formattedBasePrice = computed(() =>
    {
        if(isNaN(basePrice.value))
        {
            console.log('Base price unavailable for ' + props.order.article.code, props.order);

            return NaN;
        }

        return f(basePrice.value, 'decimal:2|thousand');
    });

    /**
     * The calculated actual discount for a basket/order line, based on its price, base price and quantity.
     *
     * @returns {Number}
     */
    const discount = computed(() =>
    {
        if(basePrice.value == 0)
        {
            return 0;
        }

        return 1 - (actualPrice.value / basePrice.value);
    });

    /**
     * The discount, formatted with 2 decimals and a percent sign.
     *
     * @returns {String}
     */
    const formattedDiscount = computed(() =>
    {
        const discount = discount.value || 0;

        return Numbers.round(discount * 100, 1) + '%';
    });

    /**
     * The special price, if any.
     * The price, otherwise.
     *
     * @returns {Number}
     */
    const actualPrice = computed(() =>
    {
        return props.order.special_price ?? price.value;
    });

    const formattedActualPrice = computed(() =>
    {
        return f(actualPrice.value, 'decimal:2|thousand');
    });


    // ------------------------------------------------------------ METHODS

    /**
     * Get the label for a basket/order line or supplement.
     *
     * @param {{}} line Order or supplement
     * @returns {String}
     */
    function getLabel(line)
    {
        return line.article.label || line.article.code || '';
    };

    function getPrototypeLabel(line)
    {
        const prototypeCode = line.article_data?.PROTOTYPE_CODE || line.article_data?.PROTOTYPE_CODE_LEGACY;

        return store.state.prototypes.prototypes.find(p => p.code === prototypeCode)?.label || prototypeCode;
    };

    function getLabelByParameterCode(parameterCode)
    {
        return t(`parameters.${parameterCode}.label`);
    };

    function getUnitByParameterCode(parameterCode)
    {
        return store.getters['parameters/getAttribute'](parameterCode, 'unit');
    }

    /**
     * Get the help message for a parameter's value,
     * or `null` if there is none.
     *
     * @param {string} code The code of the parameter.
     * @param {*} value     The value of the parameter.
     * @returns {?string}
     */
    function getHelp(code, value)
    {
        const tkey = `parameters.${code}.values.${value}.help`;
        const help = t(tkey);
        if(help === tkey)
        {
            return null;
        }

        return help;
    }



    // ------------------------------------------------------------ EXPORT

    return {
        // Constants
        debounceDelay,

        // Data
        // ...

        // Computed
        quantity,
        price,
        basePrice,
        formattedBasePrice,
        discount,
        formattedDiscount,
        actualPrice,
        formattedActualPrice,

        // Methods
        getLabel,
        getPrototypeLabel,
        getLabelByParameterCode,
        getUnitByParameterCode,
        getHelp,
    };
};
