export default class SetPrototypePreviousValuesByPreviousOrder
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const previousOrder = this.v.sides[this.side].previousOrder;
        if(!previousOrder)
        {
            return true;
        }

        // Ensure previous values have been set
        const fitting = this.v.$store.getters['lensOrder/getFitting'](this.side);
        if(fitting.getPrototype().hasPreviousValues())
        {
            console.log(`Previous values on ${this.side} side have already been set in last dispatch => no change`);
            return true;
        }

        const previousValues = {
            ...previousOrder.article_data,
            ...previousOrder.logistics_data,
            ...previousOrder.return_data,
            ...previousOrder.extra,
        };
        fitting.getPrototype().setPreviousValues(previousValues);

        console.log(`Previous values on ${this.side} side have been set`, previousValues);

        return true;
    }
}
