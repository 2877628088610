export default class PriceLenses
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const requests = [];
        const rightFitting = this.v.$store.getters['lensOrder/getFitting']('right');
        const leftFitting  = this.v.$store.getters['lensOrder/getFitting']('left');

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.pricing'));

        // @HACK FOR THERAPEUTIC - START
        const overridenValues = {
            quantity: 0,
        };

        if(rightFitting)
        {
            overridenValues.quantity += rightFitting.getPrototype().getValue('quantity');
        }

        if(leftFitting)
        {
            overridenValues.quantity += leftFitting.getPrototype().getValue('quantity');
        }
        // @HACK FOR THERAPEUTIC - END

        if(rightFitting)
        {
            console.log('Price right side', rightFitting);
            requests.push(rightFitting.price(overridenValues));
        }

        if(leftFitting)
        {
            console.log('Price left side', leftFitting);
            requests.push(leftFitting.price(overridenValues));
        }

        return Promise.all(requests)
            .then(response =>
            {
                console.log('Pricing OK !');
                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Pricing error:', error);
                this.v.showPricingError = true;

                return Promise.reject(error);
            });
    }
}
