export default class ValidateLenses
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const requests        = [];
        const options         = {};
        const eid             = this.v.$store.state.account.cEntity.id;
        const rightFitting = this.v.$store.getters['lensOrder/getFitting']('right');
        const leftFitting  = this.v.$store.getters['lensOrder/getFitting']('left');

        // Set overlay text
        this.v.$store.commit('overlay/setText', this.v.$t('overlay.validating'));

        // Validate right side
        if(rightFitting)
        {
            console.log('Validate right side');
            requests.push(rightFitting.getPrototype().validate(eid, options));
        }

        // Validate left side
        if(leftFitting)
        {
            console.log('Validate left side');
            requests.push(leftFitting.getPrototype().validate(eid, options));
        }

        return Promise.all(requests)
            .then(response =>
            {
                console.log('Validation OK !');
                return Promise.resolve(response);
            })
            .catch(error =>
            {
                console.log('Lens validation error:', error);
                return Promise.reject(error);
            });
    }
}
