<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-widget sl-ofg" @keydown.esc="close()">
        <div class="sl-widget__header">
            <div class="sl-widget__title">
                {{ $t('ofg.label') }}
            </div>
        </div>

        <div v-if="form.initialized" class="sl-widget__body sl-ofg__form">
            <ofg-field-factory
                v-for="[, param] in form.parameters"
                :key="param.code"
                :side="side"
                :parameter="param"
            />
        </div>

        <div v-else class="sl-widget__body flex justify-center items-center">
            <img src="/img/loader-5.gif" alt="Loading…" />
        </div>

        <!-- Action Buttons -->
        <div class="sl-widget__actions">
            <div
                class="sl-widget__button sl-widget__button--white"
                tabindex="3003"
                @click="close()"
                @keypress.enter="close()"
            >
                {{ $t('common.cancel') }}
            </div>

            <div
                class="sl-widget__button"
                :class="attributeButtonClass"
                tabindex="3004"
                @click="attribute"
                @keypress.enter="attribute"
            >
                <template v-if="!form.validating">
                    {{ $t(`calculators.common.attribute_${side}`) }}
                </template>

                <template v-else>
                    <img src="/img/loader.gif" alt="Loading…" class="inline-block" />
                </template>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OfgFieldFactory     from './OfgFieldFactory.vue';
import LensOrderUrlManager from '../LensOrderUrlManager';

export default
{
    name: 'SlOfg',

    components:
    {
        OfgFieldFactory,
    },

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
    },

    data()
    {
        return {
        };
    },

    computed:
    {
        form()
        {
            return this.$store.getters['lensOrder/getOfg'](this.side);
        },

        lensForm()
        {
            return this.$store.getters['lensOrder/getPrototype'](this.side);
        },

        attributeButtonClass()
        {
            return this.form.validating
                ? 'sl-widget__button--disabled'
                : 'sl-widget__button--primary';
        },

        cEntityId()
        {
            return this.$store.state.account.cEntity.id;
        },
    },

    methods:
    {
        close()
        {
            this.$store.dispatch('lensOrder/closeOfg', { side: this.side });
        },

        template()
        {
            return this.$store.dispatch('lensOrder/template', { side: this.side, target: 'ofg' });
        },

        validate()
        {
            return this.form.validate(this.cEntityId);
        },

        attribute()
        {
            if(this.form.validating)
            {
                return;
            }

            this.validate()
                .then(response =>
                {
                    // Template and attribute the output values to the lens form
                    const parameters = this.form.getParameters();
                    let oldVariant;
                    let newVariant;

                    // Loop through parameters and assign values to lens prototype
                    for(const [pCode, parameter] of parameters)
                    {
                        const oldValue = this.lensForm.getValue(pCode);
                        const newValue = parameter.defined_value;

                        // Ignore params that don't have defined value (fixed or calulated)
                        if(typeof newValue === 'undefined' || newValue === null)
                        {
                            continue;
                        }

                        // Check if lens variant changed, to reload the form with the new one
                        if(pCode === 'LENS_VARIANT')
                        {
                            oldVariant = oldValue;
                            newVariant = newValue;
                        }

                        // Set parameter value in the lens form
                        this.lensForm.setValue(pCode, newValue);
                    }

                    // Attribute the values by:
                    //   A. calling template() on the form if it's still the same variant, or;
                    //   B. reloading the form with the new variant, if it has changed.
                    let action;
                    if(oldVariant == newVariant)
                    {
                        // Scenario A
                        action = new Promise((resolve, reject) =>
                        {
                            this.$store.dispatch('lensOrder/template', { side: this.side })
                                .then(response => resolve(response))
                                .catch(error => reject(error));
                        });
                    }
                    else
                    {
                        // Scenario B
                        const lensOrderUrlManager = new LensOrderUrlManager(this.$route);
                        const currentPrototype = this.$store.getters['prototypes/getByCode'](lensOrderUrlManager.getPrototypeCode(this.side));
                        const newPrototypeCode = currentPrototype.model_code + '_' + newVariant;

                        lensOrderUrlManager.setPrototypeCode(this.side, newPrototypeCode);

                        action = new Promise((resolve, reject) =>
                        {
                            this.$router.replace({ path: lensOrderUrlManager.getPath() })
                                .then(response => resolve(response))
                                .catch(error => reject(error));
                        });
                    }

                    action
                        .then(response =>
                        {
                            // nothing
                        })
                        .catch(error =>
                        {
                            // nothing
                        })
                        .then(() =>
                        {
                            this.close();
                        });
                })
                .catch(error =>
                {
                    // Validation error => do nothing
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
