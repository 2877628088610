import { computed, toValue } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

export default function useDeliveryNoteComposable(props)
{
    // ------------------------------------------------------------ COMPOSABLE

    const { t } = useI18n({ useScope: 'global' });


    // ------------------------------------------------------------ COMPUTED

    /**
     * The formatted date of the delivery note.
     * The `dateField` prop determines which property of the delivery note to use.
     *
     * @return {string}
     */
    const deliveryNoteDate = computed(() =>
    {
        return moment(toValue(props.deliveryNote)[toValue(props.dateField)]).format('DD.MM.YYYY');
    });

    /**
     * Get the tracking URL for this delivery note.
     *
     * @return {string}
     */
    const trackingUrl = computed(() =>
    {
        // Ensure we have a tracking code
        const tracking = toValue(props.deliveryNote).tracking;
        if(!tracking)
        {
            return '';
        }

        // Determine the right URL depending on the distributor
        let urlFormat = '';
        switch(toValue(props.deliveryNote).fk_code_distributeur)
        {
            case 'CIBLEX_FR':
                urlFormat = 'http://extranet.ciblex.fr/extranet/client/corps.php?module=colis&colis=%s';
                break;

            case 'CONIL':
                urlFormat = ''; // @todo
                break;

            case 'DATALENS':
                urlFormat = ''; // @todo
                break;

            case 'DHL':
                urlFormat = 'https://www.dhl.com/ch-en/home/tracking/tracking-express.html?submit=1&tracking-id=%s';
                break;

            case 'GEN_OPTIQUE':
                urlFormat = ''; // @todo
                break;

            case 'GLS_FR':
                urlFormat = ''; // @todo
                break;

            case 'JULIA':
                urlFormat = ''; // @todo
                break;

            case 'L_MAX':
                urlFormat = ''; // @todo
                break;

            case 'MICRO':
                urlFormat = ''; // @todo
                break;

            case 'NACEX':
                urlFormat = ''; // @todo
                break;

            case 'NO7LOG':
                urlFormat = ''; // @todo
                break;

            case 'NSL':
                urlFormat = ''; // @todo
                break;

            case 'OCS':
                urlFormat = ''; // @todo
                break;

            case 'PCH':
                urlFormat = 'https://service.post.ch/EasyTrack/submitParcelData.do?formattedParcelCodes=%s';
                break;

            case 'PIELLE':
                urlFormat = ''; // @todo
                break;

            case 'PIERRE':
                urlFormat = ''; // @todo
                break;

            case 'PROLENS':
                urlFormat = ''; // @todo
                break;

            case 'THE_DX':
                urlFormat = ''; // @todo
                break;

            case 'TMED_AT':
                urlFormat = ''; // @todo
                break;

            case 'TMED_AT_EDI':
                urlFormat = ''; // @todo
                break;

            case 'TMED_DE':
                urlFormat = ''; // @todo
                break;

            case 'TMED_DE_EDI':
                urlFormat = ''; // @todo
                break;

            case 'TNT':
                urlFormat = ''; // @todo
                break;

            case 'TRUEBLUE':
                urlFormat = ''; // @todo
                break;

            case 'VCI':
                urlFormat = ''; // @todo
                break;
        }

        // Inject the tracking code into the URL and return it
        return urlFormat.replace('%s', tracking);
    });

    /**
     * The tooltip notice displayed when hovering an expired tracking link.
     *
     * @return {string}
     */
    const trackingStatus = computed(() =>
    {
        if(!hasTracking.value)
        {
            // No valid tracking code/URL is known
            return t('invoicing.delivery_notes.no_tracking_url');
        }
        else if(!canTrack.value)
        {
            // The tracking code is older than 2 weeks
            return t('invoicing.delivery_notes.tracking_expired');
        }
        else
        {
            // The tracking URL is known and recent; nothing to say :-)
            return '';
        }
    });

    /**
     * Whether the current delivery note has a tracking URL.
     *
     * @return {boolean}
     */
    const hasTracking = computed(() =>
    {
        return !!trackingUrl.value;
    });

    /**
     * Whether the current delivery note has a tracking code.
     * The delivery note must not be older than 1 month for the tracking code to be available.
     *
     * @return {boolean}
     */
    const canTrack = computed(() =>
    {
        const threshold = moment().subtract(1, 'month');
        const deliveryNoteDate = moment(toValue(props.deliveryNote).fermeture);

        return hasTracking.value && deliveryNoteDate.isSameOrAfter(threshold, 'day');
    });


    // ------------------------------------------------------------ EXPORT

    return {
        deliveryNoteDate,
        trackingUrl,
        trackingStatus,
        hasTracking,
        canTrack,
    };
};
