export default class SetFittingValuesByBasketLine
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;
        if(!lensOrderUrlManager.hasBasketLineId(this.side))
        {
            return true;
        }

        const basketLineId = lensOrderUrlManager.getBasketLineId(this.side);
        const basketLine = this.v.$store.getters['basket/getById'](basketLineId);
        const fitting = this.v.$store.getters['lensOrder/getFitting'](this.side);

        fitting.basket_line_id = basketLine.id;

        return true;
    }
}
