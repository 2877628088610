export default class AddToBasket
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const basketLines = [];
        const rightFitting = this.v.$store.getters['lensOrder/getFitting']('right');
        const leftFitting  = this.v.$store.getters['lensOrder/getFitting']('left');
        const diff = 1572562800000; // 01.11.2019 00:00:00
        const uid = Math.floor( (Date.now() - diff) / 10) + 10000000000;
        const dependencyCode = uid.toString();

        this.v.$store.commit('overlay/setText', this.v.$t('overlay.adding_to_basket'));

        if(rightFitting)
        {
            basketLines.push(rightFitting.toBasketLine());
        }

        if(leftFitting)
        {
            basketLines.push(leftFitting.toBasketLine());
        }

        if(basketLines.length > 1)
        {
            console.log(`Dependency code ${dependencyCode} has been set on both sides`);

            basketLines[0].dependency_code = dependencyCode;
            basketLines[1].dependency_code = dependencyCode;
        }
        else if(rightFitting && rightFitting.getPrototype().getValue('quantity') > 1)
        {
            console.log(`Dependency code ${dependencyCode} has been set right side because quantity is more than 1`);

            // Force dependency between lenses when there is more than one.
            basketLines[0].dependency_code = dependencyCode;
        }
        else if(leftFitting && leftFitting.getPrototype().getValue('quantity') > 1)
        {
            console.log(`Dependency code ${dependencyCode} has been set on left side because quantity is more than 1`);

            // Force dependency between lenses when there is more than one.
            basketLines[0].dependency_code = dependencyCode;
        }
        else
        {
            console.log('No dependency code set because there is only one side with a quantity of 1');

            // Reset dependency since there is only one lens.
            basketLines[0].dependency_code = null;
        }

        return this.v.$store.dispatch('basket/saveLines', basketLines)
            .then(reponse =>
            {
                console.log('These lines have been added to the basket:', basketLines);

                return Promise.resolve(reponse);
            });
    }
}
