// ====================
// Notifications Module
// ====================

import {
    default as Notification,
    NOTIFICATION_TARGETS,
} from "@/models/Notification";


export function setupNotificationsModule(i18n)
{
    let notificationsCounter = 1; // necessary for <transition-group>

    return {
        namespaced: true,

        state()
        {
            return {
                /** List of notifications that are always displayed in a band at the top of every page. */
                band: [],

                /** List of notifications that are hidden in a drop-down menu in the navbar. */
                tray: [],
            };
        },

        getters:
        {
            hasNotification: state => ({ target, id }) => state[target].some(n => n.id === id),

            hasBandNotification: state => id => state.band.some(n => n.id === id),

            hasTrayNotification: state => id => state.tray.some(n => n.id === id),
        },

        mutations:
        {
            addBandNotification(state, notification)
            {
                if(!notification.id || typeof notification.id !== 'string')
                {
                    throw new Error('Cannot add a notification without a string ID');
                }

                // Add or replace notification in band
                const index = state.band.findIndex(n => n.id === notification.id);
                if(index === -1)
                {
                    state.band.push(notification); // add
                }
                else
                {
                    state.band.splice(index, 1, notification); // replace
                }
            },


            addTrayNotification(state, notification)
            {
                if(!notification.id)
                {
                    throw new Error('Cannot add notification without an ID');
                }

                // Add or replace notification in tray
                const index = state.tray.findIndex(n => n.id === notification.id);
                if(index === -1)
                {
                    state.tray.push(notification); // add
                }
                else
                {
                    state.tray.splice(index, 1, notification); // replace
                }
            },


            removeBandNotification(state, index)
            {
                state.band.splice(index, 1);
            },


            removeTrayNotification(state, index)
            {
                state.tray.splice(index, 1);
            },
        },

        actions:
        {
            // ------------------------------------------------------------ ADD NOTIFICATIONS

            addNotification({ commit }, notification)
            {
                return new Promise((resolve, reject) =>
                {
                    notification = new Notification(notification);

                    // Translate the contents of the notification (if any)
                    if(notification.title)
                    {
                        notification.title = i18n.global.t(notification.title);
                    }
                    if(notification.text)
                    {
                        notification.text = i18n.global.t(notification.text);
                    }

                    switch(notification.target)
                    {
                        case 'band':
                            commit('addBandNotification', notification);
                            break;

                        case 'tray':
                            commit('addTrayNotification', notification);
                            break;

                        default:
                            return reject(`Invalid notification target: "${notification.target}"`);
                    }

                    resolve(notification.id);
                });
            },

            addBandNotification({ dispatch }, notification)
            {
                return dispatch('addNotification', { ...notification, target: 'band' });
            },

            addTrayNotification({ dispatch }, notification)
            {
                return dispatch('addNotification', { ...notification, target: 'tray' });
            },


            // ------------------------------------------------------------ REMOVE NOTIFICATIONS

            removeNotification({ commit, state }, { target, id })
            {
                return new Promise((resolve, reject) =>
                {
                    if(!NOTIFICATION_TARGETS.includes(target))
                    {
                        return reject(`Invalid notification target: "${target}"`);
                    }

                    const index = state[target].findIndex(n => n.id === id);
                    if(index !== -1)
                    {
                        const notification = JSON.parse(JSON.stringify(state[target][index]));

                        switch(target)
                        {
                            case 'band':
                                commit('removeBandNotification', index);
                                break;

                            case 'tray':
                                commit('removeTrayNotification', index);
                                break;

                            default:
                                return reject(`Invalid notification target: "${target}"`);
                        }

                        resolve(notification);
                    }
                    else
                    {
                        resolve(null);
                    }
                });
            },

            removeBandNotification({ dispatch }, id)
            {
                return dispatch('removeNotification', { target: 'band', id });
            },


            removeTrayNotification({ dispatch }, id)
            {
                return dispatch('removeNotification', { target: 'tray', id });
            },
        },
    };
};
