/**
 * Convert an angle from degrees to radians.
 *
 * @param {number} deg The angle in degrees.
 * @returns {number} The angle in radians.
 */
export function deg2rad(deg)
{
    return deg * (Math.PI / 180);
}

/**
 * Convert an angle from radians to degrees.
 *
 * @param {number} rad The angle in radians.
 * @returns {number} The angle in degrees.
 */
export function rad2deg(rad)
{
    return rad * 180 / Math.PI;
}

/**
 * Calculate the sagittal difference between 2 diameters.
 * DASAG: Differential Asphere Sagittal
 *
 * @param {number} r  The radius.
 * @param {number} e  The eccentricity.
 * @param {number} d1 The starting diameter.
 * @param {number} d2 The ending diameter.
 * @returns {number} The calculated sagittal.
 */
export function daSag(r, e, d1, d2)
{
    d1 /= 2;
    d2 /= 2;

    const k = kOfEx(e);

    const sqStart = (1 + k) * (d2 ** 2) / (r ** 2);
    const sqEnd   = (1 + k) * (d1 ** 2) / (r ** 2);

    if(sqStart >= 1 || sqEnd >= 1)
    {
        throw new RangeError(
            'Combination of radius and eccentricity exceed the diameter' +
            `(r: ${r.toFixed(2)}, e: ${e.toFixed(2)}, d: ${d2.toFixed(2)})`
        );
    }

    return ((d2 ** 2) / (r * (1 + Math.sqrt(1 - sqStart)))) -
           ((d1 ** 2) / (r * (1 + Math.sqrt(1 - sqEnd))));
}

/**
 * K from Ex | progressive eccentricity.
 *
 * @param {number} e The eccentricity.
 * @returns {number} The calculated K.
 */
export function kOfEx(e)
{
    // Prevent division by zero
    if(e === 1)
    {
        e = 0.999;
    }

    if(e >= 0)
    {
        // Conic constant
        return -1 * (e ** 2);
    }

    // Negative eccentricity => Prolate ellipse with k > 0
    return 1 / ((1 - e) ** 2) - 1;
}

/**
 * Provide the radius of an ellipse in function of a selected eccentricity,
 * given the targeted difference of SAG and the two diameters.
 *
 * @param {number} deltaSag The difference in sagittal.
 * @param {number} ecc      The eccentricity.
 * @param {number} d1       The starting diameter.
 * @param {number} d2       The ending diameter.
 * @returns {number}
 */
export function radiusDeltaSagEx(deltaSag, ecc, d1, d2)
{
    d1 /= 2;
    d2 /= 2;

    const y = (d2 ** 2) - (d1 ** 2);
    const e = 1 - (ecc ** 2);

    const firstX = (y / (2 * deltaSag * e)) + deltaSag / 2;

    return Math.sqrt((e ** 2) * (firstX ** 2) + (d1 ** 2) * e);
}

/**
 * Calculate the eccentricity of a lens.
 *
 * @param {number} rad The radius.
 * @param {number} sag The sagittal.
 * @param {number} dia The diameter.
 * @returns {number}
 */
export function exSRDbis(rad, sag, dia)
{
    dia /= 2;

    const T1 = (dia ** 2 - (sag * rad)) / sag;
    const Ex = - (T1 ** 2 - rad ** 2) / (dia ** 2);

    let kk = Ex - 1;

    if(Math.abs(kk) < 0.0001)
    {
        kk = 0;
    }

    if(rad < dia)
    {
        return 0;
    }

    if(kk <= 0)
    {
        return Math.sqrt(-kk);
    }

    return -Math.sqrt(kk / (kk + 1));
}
