<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-column" :class="columnClasses">
        <!-- Label -->
        <div class="sl-order-form__label-wrapper">
            <span class="sl-order-form__label">
                {{ parameter.form_label }}
            </span>

            <span v-if="parameter.unit" class="sl-order-form__unit">
                [&nbsp;{{ parameter.unit }}&nbsp;]
            </span>
        </div>

        <!-- Field -->
        <div class="sl-order-form__field-wrapper">
            <input
                v-model.lazy="tempValue"
                class="sl-order-form__input"
                :class="{ 'cursor-not-allowed': disabled }"
                type="text"
                :name="name"
                :tabindex="tabIndex"
                autocomplete="off"
                :disabled="disabled"
                @focus="onFocus"
                @blur="onBlur"
            >
        </div>

        <!-- Error -->
        <div v-if="error" class="mt-1 text-red-500 text-xs text-center">
            {{ error }}
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { toNumber } from 'lodash-es';
import AbstractInlineField from './AbstractInlineField.vue';

export default
{
    name: 'InlineInputNumberField',

    extends: AbstractInlineField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        disabled()
        {
            return !this.parameter.is_enabled;
        },

        tempValue:
        {
            get()
            {
                if(this.isFocused)
                {
                    return this.modelValue;
                }
                else
                {
                    return this.formattedValue;
                }
            },

            set(value)
            {
                if(value === null || value === this.value || value === '-' || value === '+')
                {
                    return;
                }

                if(typeof value === 'string')
                {
                    value = value.replace(',', '.');
                    value = value.replace(/[^0-9.-]/gi, '');
                }

                value = (value !== '') ? toNumber(value) : null;

                this.$emit('update:modelValue', value);
            },
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line-column .sl-order-form__field-wrapper
{
    @apply mx-0;
}

// Highlight focused input
.sl-order-form__input:focus
{
    @apply outline-none border-primary-400;
}
</style>
