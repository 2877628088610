// ===============
// Patients Module
// ===============

import axios from '@/axios';

export default
{
    namespaced: true,


    // ------------------------------------------------------------ STATE

    state:
    {
        all: [],
    },


    // ------------------------------------------------------------ GETTERS

    getters:
    {
        getById: state => id =>
        {
            throw new Error('getter "patients/getById" is deprecated');
        },
    },


    // ------------------------------------------------------------ MUTATIONS

    mutations:
    {
        setAll(state, patients)
        {
            state.all = patients;
        },
    },


    // ------------------------------------------------------------ ACTIONS

    actions:
    {
        fetchAll({ commit, rootState })
        {
            return new Promise((resolve, reject) =>
            {
                const url = '/api/patients/entity/:eid'
                    .replace(':eid', rootState.account.cEntity.id);

                axios.get(url)
                    .then(({ data: patients }) =>
                    {
                        commit('setAll', patients);

                        resolve(patients);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            });
        },

        clearAll({ commit })
        {
            commit('setAll', []);
        },
    },
};
