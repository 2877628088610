<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-widget sl-calculator sl-pawbcor" @keydown.esc="$emit('close')">
        <div class="sl-widget__header">
            <div class="sl-widget__title">
                {{ $t('calculators.power_adjustment_with_bc_changes_on_rgp.title') }}
            </div>
        </div>

        <div class="sl-widget__body">
            <div class="grid grid-cols-2 gap-2 justify-center items-center text-center">
                <!-- ---------------------------------------- Calculator Line 1: Current Base Curve -->

                <div>
                    {{ $t('calculators.power_adjustment_with_bc_changes_on_rgp.current_base_curve') }}
                </div>

                <div>
                    <lsn-input
                        v-model="currentBaseCurve"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-pawbcor__input"
                        @update:model-value="calculate"
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 2: Current Sphere -->

                <div>
                    {{ $t('calculators.power_adjustment_with_bc_changes_on_rgp.current_sphere') }}
                </div>

                <div>
                    <lsn-input
                        v-model="currentSphere"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-pawbcor__input"
                        @update:model-value="calculate"
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 3: New Base Curve -->

                <div>
                    {{ $t('calculators.power_adjustment_with_bc_changes_on_rgp.new_base_curve') }}
                </div>

                <div>
                    <lsn-input
                        v-model="newBaseCurve"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-pawbcor__input"
                        @update:model-value="calculate"
                    />
                </div>


                <!-- ---------------------------------------- Calculator Line 4: Result (New Sphere) -->

                <div>
                    {{ $t('calculators.power_adjustment_with_bc_changes_on_rgp.new_sphere') }}
                </div>

                <div>
                    <lsn-input
                        v-model="newSphere"
                        placeholder="0.00"
                        class="lsn-form__wrapper--padless sl-pawbcor__input"
                        disabled
                    />
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="sl-widget__actions">
            <div
                v-if="rightFitting"
                class="sl-widget__button"
                :class="attributeButtonClass"
                tabindex="0"
                @click="attribute('right')"
                @keypress.enter="attribute('right')"
            >
                {{ $t('calculators.common.attribute_right') }}
            </div>

            <div
                v-if="leftFitting"
                class="sl-widget__button"
                :class="attributeButtonClass"
                tabindex="0"
                @click="attribute('left')"
                @keypress.enter="attribute('left')"
            >
                {{ $t('calculators.common.attribute_left') }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
export default
{
    name: 'PowerAdjustment',

    emits: ['close'],

    data()
    {
        return {
            attributing:      false,
            currentBaseCurve: 7.80,  // sample default values
            currentSphere:    -3.00, // sample default values
            newBaseCurve:     7.75,  // sample default values
            newSphere:        0.00,
        };
    },

    computed:
    {
        attributeButtonClass()
        {
            return this.attributing
                ? 'sl-widget__button--disabled'
                : 'sl-widget__button--primary';
        },

        rightFitting()
        {
            return this.$store.getters['lensOrder/getFitting']('right');
        },

        leftFitting()
        {
            return this.$store.getters['lensOrder/getFitting']('left');
        },
    },

    mounted()
    {
        this.calculate();
    },

    methods:
    {
        calculate()
        {
            // Convert input to float
            this.currentBaseCurve = parseFloat(`${this.currentBaseCurve}`.replace(/,/g, '.'));
            this.currentSphere    = parseFloat(`${this.currentSphere}`.replace(/,/g, '.'));
            this.newBaseCurve     = parseFloat(`${this.newBaseCurve}`.replace(/,/g, '.'));

            this.newSphere = (this.currentSphere + (this.currentBaseCurve - this.newBaseCurve) * -5).toFixed(2);
        },

        attribute(side)
        {
            if(this.attributing)
            {
                return;
            }

            if(!['right', 'left'].includes(side))
            {
                throw new RangeError(`Invalid value for side: "${side}"`);
            }

            this.attributing = true;

            const fitting = this[`${side}Fitting`];

            // Values will be set only if parameter exists.
            fitting.getPrototype().setValue('LENS_SPHERE', parseFloat(this.newSphere));

            this.$store.dispatch('lensOrder/template', { side })
                .then(responses =>
                {
                    this.$emit('close');
                })
                .finally(() =>
                {
                    this.attributing = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-pawbcor :deep(.sl-pawbcor__input input)
{
    @apply text-center;
}
</style>
