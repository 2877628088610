// ==================
// Calculators Module
// ==================


export default function setupCalculatorsModule(i18n)
{
    return {
        namespaced: true,

        state()
        {
            return {
                overRefraction: {
                    panelOpen: false,
                    side:      null,
                },
            };
        },

        getters:
        {
        },

        mutations:
        {
            setOverRefractionPanelOpen(state, { isOpen, side })
            {
                isOpen = !!isOpen;
                if(isOpen && !['left', 'right'].includes(side))
                {
                    throw new RangeError(`Invalid value for side: "${side}"`);
                }

                state.overRefraction.side = side;
                state.overRefraction.panelOpen = !!isOpen;
            },
        },

        actions:
        {
            openOverRefractionPanel({ commit }, side)
            {
                return new Promise((resolve, reject) =>
                {
                    const options = { isOpen: true, side };
                    commit('setOverRefractionPanelOpen', options);

                    resolve(options);
                });
            },

            closeOverRefractionPanel({ commit })
            {
                commit('setOverRefractionPanelOpen', { isOpen: false });
            },
        },
    };
};
