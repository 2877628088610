export default class SetFittingValuesByPreviousOrder
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const previousOrder = this.v.sides[this.side].previousOrder;
        if(!previousOrder)
        {
            return true;
        }

        const fitting = this.v.$store.getters['lensOrder/getFitting'](this.side);

        // If this is a re-order => set previous reference
        if(!this.v.isOrderEdition)
        {
            fitting.reference = null;
            fitting.previous_order_reference = previousOrder.reference;

            console.log(`Fitting previous reference was set to ${previousOrder.reference}`);
        }
        else // This is an order edition => set reference.
        {
            fitting.reference = previousOrder.reference;
            fitting.previous_order_reference = previousOrder.previous_order_reference;
            fitting.currency_code = previousOrder.currency_code;
            fitting.special_price = previousOrder.special_price;

            // Sum order price and all order supplements price.
            let fullPrice = previousOrder.price;
            for(const supplement of previousOrder.supplements)
            {
                fullPrice += supplement.price;
            }

            fitting.customer_price = fullPrice;

            console.log(`Fitting reference was set to ${previousOrder.reference}`);
        }

        return true;
    }
}
