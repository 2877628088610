<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="hidden lg:flex sl-dropdown-menu">
        <!-- icons -->
        <div class="sl-navbar__icon-item">
            <mdi-icon icon="mdiAccount" />
        </div>

        <div class="sl-dropdown-menu__content">
            <div class="w-full flex items-center mb-2">
                <div class="sl-dropdown-menu__account-icon-wrapper">
                    <mdi-icon icon="mdiAccount" class="sl-dropdown-menu__account-icon" />
                </div>

                <div class="text-left">
                    <div class="text-gray-600 text-sm">
                        {{ fullname }}
                    </div>
                    <!-- <div class="mt-1 text-primary-500 text-xs">modifier</div> -->
                </div>
            </div>

            <!-- Invoicing -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'my-account', params: $route.params }" custom>
                <div class="sl-dropdown-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('my_account.page_title') }}
                </div>
            </router-link>

            <div class="border-t border-primary-10 my-2"></div>

            <!-- <div class="sl-dropdown-menu__link-item">
                Aperçu de l'entreprise
            </div>

            <div class="sl-dropdown-menu__link-item">
                Retourner un article
            </div>

            <div class="sl-dropdown-menu__link-item">
                Retours
            </div> -->

            <!-- Invoicing -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'invoice-list', params: $route.params }" custom>
                <div v-can="'invoice:view'" class="sl-dropdown-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('dropdown_menu.invoicing') }}
                </div>

                <div v-can="'invoice:view'" class="border-t border-primary-10 my-2"></div>
            </router-link>

            <!-- Contact Details -->
            <router-link v-slot="{ navigate, isActive }" :to="{ name: 'contact-details', params: $route.params }" custom>
                <div v-can="'contact-details:edit'" class="sl-dropdown-menu__link-item" :class="{ 'sl-side-menu__item--active': isActive }" @click="navigate">
                    {{ $t('dropdown_menu.contact_details') }}
                </div>

                <div v-can="'contact-details:edit'" class="border-t border-primary-10 my-2"></div>
            </router-link>

            <!-- SwissLens Website -->
            <a href="https://www.swisslens.ch" target="_blank" class="sl-dropdown-menu__link-item">
                {{ $t('dropdown_menu.swisslens_website') }}
                <mdi-icon icon="mdiOpenInNew" class="sl-dropdown-menu__link-item-icon" />
            </a>

            <div class="border-t border-primary-10 my-2"></div>

            <!-- Logout -->
            <div class="sl-dropdown-menu__link-item" @click="logout">
                {{ $t('dropdown_menu.logout') }}
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import Numbers from '@/utils/Numbers';

export default
{
    name: 'DropdownMenu',

    computed:
    {
        fullname()
        {
            const cUser = this.$store.state.account.cUser;
            if(!cUser)
            {
                throw new ReferenceError('$store.state.account.cUser is empty');
            }

            if(!cUser?.last_name || !cUser?.first_name)
            {
                return cUser.email;
            }

            return cUser.first_name + ' ' + cUser.last_name;
        },

        discount()
        {
            return Numbers.round(this.$store.state.account.cEntity.discount, 2);
        },
    },

    methods:
    {
        logout()
        {
            this.$store.dispatch('auth/logout');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
$navbar-height: 50px;

.sl-dropdown-menu
{
    position: relative;
}

.sl-dropdown-menu__content
{
    @apply hidden px-8 py-4 shadow-lg bg-white;
}

.sl-dropdown-menu:hover .sl-dropdown-menu__content
{
    position: absolute;
    display: block;
    min-width: 280px;
    right: 0;
    top: $navbar-height;
}

.sl-dropdown-menu__link-item
{
    @apply block w-full py-3 text-left text-sm text-gray-500 cursor-pointer;
}

.sl-dropdown-menu__link-item:hover
{
    @apply underline;
}

.sl-dropdown-menu__account-icon-wrapper
{
    width: 50px;
}

.sl-dropdown-menu__account-icon
{
    @apply -ml-2 w-[50px] h-[50px] text-gray-400;
}

.sl-dropdown-menu__link-item-icon
{
    @apply inline-block align-middle -mt-0.5 ml-2.5 w-5 h-5 text-gray-400;
}
</style>
