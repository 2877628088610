<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-credit-note-table__column sl-credit-note__date">
        {{ creditNoteDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-credit-note-table__column sl-credit-note__reference">
        {{ creditNote.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-credit-note-table__column sl-credit-note__price">
        {{ f(creditNote.total_brut, 'decimal:2|thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-credit-note-table__column sl-credit-note__currency">
        {{ creditNote.fk_reference_monnaie }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-credit-note-table__column sl-credit-note__actions">
        <lsn-loader-link
            :label="t('common.download')"
            :is-loading="isFileDownloading"
            @click.stop="downloadFile"
        />
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import LsnLoaderLink from '@/components/LsnLoaderLink.vue';
import { useStore } from 'vuex';
import { f } from '@/utils/vue-formatter';
import useFileDownloader from '@/composables/FileDownloader';


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = useStore();

const { isFileDownloading, downloadFile } = useFileDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.creditNote.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/credit-note/${eid}/${documentId}/pdf`;
    },
    () => props.creditNote.reference + '.pdf'
);


// ------------------------------------------------------------ PROPS

const props = defineProps({
    creditNote:
    {
        type:     Object,
        required: true,
    },

    /**
     * The property of the credit note to use for its `date` column.
     */
    dateField:
    {
        type:     String,
        required: true,
    },
});


// ------------------------------------------------------------ COMPUTED

/**
 * The formatted date of the credit note.
 * The `dateField` prop determines which property of the credit note to use.
 * @return {string}
 */
const creditNoteDate = computed(() =>
{
    return moment(props.creditNote[props.dateField]).format('DD.MM.YYYY');
});
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>
