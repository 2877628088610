<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-main">
        <div class="sl-page sl-page--full">
            <div class="sl-page__header">
                <div class="sl-page__jumbotron">
                    {{ $t('order_confirmation.jumbotron') }}
                </div>

                <div v-if="isConfirmationEmailSent" class="m-auto max-w-md text-center text-gray-600 leading-snug">
                    <p>{{ $t('order_confirmation.got_your_order') }}</p>
                    <br>
                    {{ $t('order_confirmation.your_confirmation_email') }}:

                    <div v-for="email in emails" :key="email.id" class="text-primary-500 py-1">
                        {{ email.cvalue }}
                    </div>

                    <span class="text-gray-500 text-sm">
                        {{ $t('order_confirmation.check_spam_folder') }}
                    </span>
                </div>

                <div v-else class="m-auto max-w-md text-center text-gray-600 leading-snug">
                    <p>{{ $t('order_confirmation.got_your_order') }}</p>
                    <br>
                </div>
            </div>

            <div class="sl-page__content">
                <div class="text-xl text-primary-500 mt-5 mb-8 ml-3">
                    {{ $t('order_confirmation.your_order_summary') }}
                </div>

                <order-table
                    class="sl-order-table--confirmation"
                    :orders="orders"
                    context="order-confirmation"
                />

                <div class="sl-total-line">
                    <div class="sl-total-line__label">
                        {{ $t('common.total') }}
                    </div>

                    <div class="sl-total-line__amount">
                        {{ currencyCode }}&nbsp;{{ total }}
                    </div>

                    <div class="sl-total-line__text w-full">
                        ({{ $t('common.excluding-taxes') }})
                    </div>
                </div>

                <div class="mt-10 mx-4 text-gray-500 leading-relaxed text-sm text-center">
                    {{ $t('order_confirmation.price_list_warning') }}
                </div>

                <div class="flex mt-12">
                    <div class="w-1/3"></div>

                    <div class="w-1/3"></div>

                    <div class="w-1/3 flex justify-end">
                        <div>
                            <div class="pb-4 text-gray-600 text-center">
                                {{ $t('order_confirmation.question') }}
                            </div>

                            <div class="flex items-center py-3 px-6 text-primary-500 text-sm border border-b-0 border-gray-300">
                                <mdi-icon icon="mdiPhone" class="sl-contact-icon" />
                                <a :href="'tel:' + $cc.phone()">{{ $cc.phone() }}</a>
                            </div>

                            <div class="flex items-center py-3 px-6 text-primary-500 text-sm border border-gray-300 tracking-wider">
                                <mdi-icon icon="mdiEmailOutline" class="sl-contact-icon" />
                                <a :href="'mailto:' + $cc.email()">{{ $cc.email() }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import OrderTable from '@/components/order/OrderTable.vue';

export default
{
    components:
    {
        OrderTable,
    },

    computed:
    {
        emails()
        {
            return this.$store.state.account.cEntity.contacts.filter(
                contact => contact.type === 'EMAIL' && (contact.notification_distributions || '').indexOf('ORDERS_CONFIRMATION') !== -1
            );
        },

        orders()
        {
            return this.$store.state.basket.lastOrders;
        },

        total()
        {
            let total = 0;

            for(let i in this.orders)
            {
                const order = this.orders[i]; // Shortcut

                if(order.special_price !== null)
                {
                    // Special price overrides any quantity and article/supplement price
                    total += order.special_price;
                }
                else
                {
                    total += order.quantity * order.price;

                    for(let y in order.supplements)
                    {
                        const supplement = order.supplements[y]; // Shortcut

                        total += supplement.quantity * supplement.price;
                    }
                }
            }

            return this.$f(total, 'decimal:2|thousand');
        },

        currencyCode()
        {
            return this.orders?.[0]?.currency_code || '';
        },

        isConfirmationEmailSent()
        {
            if(!this.emails.length)
            {
                return false;
            }

            const lastOptions = this.$store.state.basket.lastOptions;

            if(lastOptions && !lastOptions.send_confirmation_email)
            {
                return false;
            }

            return true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-total-line
{
    @apply flex flex-wrap mt-4;
}

.sl-total-line__label
{
    @apply grow mr-4 text-gray-600 text-right;
}

.sl-total-line__amount
{
    width: 120px;
    @apply mr-3 text-right text-primary-500;
}

.sl-total-line__text
{
    @apply w-full mr-3 mt-3 text-xs text-right text-gray-500;
}

.sl-contact-icon
{
    @apply inline-block align-middle mr-5 w-5 h-5 text-gray-600;
}
</style>
