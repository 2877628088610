<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="h-screen w-full bg-primary-10">
        <div class="sl-entities-switch">
            <!-- Header -->
            <div class="sl-entities-switch__header">
                <img class="sl-entities-switch__logo" src="/img/logo-swisslens-blue.svg" alt="SwissLens SA" />
            </div>

            <!-- Content -->
            <div v-if="$store.state.account.entitiesCount" class="sl-entities-switch__content">
                <!-- Title -->
                <h1 class="mb-5 text-2xl text-gray-500 text-center">
                    {{ $t('entities-switch.title') }}
                </h1>

                <!-- If more than 10 entities => display search field -->
                <div
                    v-if="$store.state.account.entitiesCount > $const.entities.SWITCHER_THRESHOLD"
                    class="sl-search-input__wrapper"
                >
                    <mdi-icon icon="mdiMagnify" class="w-7 h-7" />

                    <input
                        ref="searchInput"
                        v-model="searchText"
                        type="text"
                        class="sl-search-input"
                        autofocus
                        @input="onSearchInput"
                        @keyup.enter="selectFirstEntity()"
                    >

                    <!-- Filter by Country -->
                    <div
                        v-is-staff
                        class="sl-search-input__country"
                        :class="{ 'sl-search-input__country--searching': !entities.length }"
                    >
                        <select
                            v-model="filters.country"
                            @change="onSearchInput"
                        >
                            <option value="">
                                {{ $t('entities-switch.filters.country.label') }}
                            </option>

                            <option
                                v-for="(countryName, countryCode) in countries"
                                :key="countryCode"
                                :value="countryCode"
                            >
                                {{ countryName }}
                                ({{ countryCode.toUpperCase() }})
                            </option>
                        </select>
                    </div>

                    <div v-if="entities.length" class="sl-search-input__nb-results">
                        {{ entities.length }}
                    </div>
                </div>

                <!-- Entities List Scroll -->
                <perfect-scrollbar
                    v-if="!searchLoading || entities.length"
                    ref="ps"
                    class="sl-search-result__wrapper ps--visible"
                >
                    <!-- Entities List -->
                    <div
                        v-for="entity in entities.slice(0, 50)"
                        :key="entity.id"
                        class="sl-entity"
                        :class="{ 'sl-entity--not-selectable': !isSelectable(entity) }"
                        tabindex="0"
                        @click="selectEntity(entity)"
                        @keyup.enter="selectEntity(entity)"
                        @keyup.esc="$refs.searchInput.focus()"
                    >
                        <div class="flex items-center">
                            <div class="shrink-0 mr-5 w-[65px] text-gray-300 text-center text-3xl">
                                {{ entity.country }}
                            </div>

                            <div class="grow">
                                <!-- Name -->
                                <div class="sl-entity__line">
                                    <div class="sl-entity__name">
                                        {{ entity.ext_ref || entity.name }}
                                    </div>

                                    <div class="sl-entity__status-wrapper">
                                        <div class="sl-entity__status" :class="getStatusClass(entity)">
                                            {{ getStatusText(entity) }}
                                        </div>
                                    </div>
                                </div>

                                <!-- Recipient Name (a.k.a. "Raison sociale") -->
                                <div v-if="hasRecipientName(entity)" class="sl-entity__line">
                                    <div class="sl-entity__recipient-name">
                                        {{ entity.recipient_name }}

                                        <template v-if="entity.recipient_name_affix">
                                            - {{ entity.recipient_name_affix }}
                                        </template>
                                    </div>
                                </div>

                                <!-- Address -->
                                <div class="sl-entity__line mt-1">
                                    <div class="sl-entity__address">
                                        {{ entity.postcode }} - {{ entity.city }}
                                    </div>

                                    <div class="sl-entity__ext-id">
                                        {{ entity.ext_id }}
                                    </div>
                                </div>

                                <!-- Group Information -->
                                <div v-if="entity.group_name" class="sl-entity__line border-t border-primary-200 border-dashed mt-1 pt-1">
                                    <div class="w-full text-gray-400 text-sm">
                                        {{ $t('entities-switch.member_of') }} {{ entity.group_name }}
                                    </div>

                                    <div v-if="entity.member_code" class="w-full text-gray-400 text-xs">
                                        {{ $t('common.member_code') }} {{ entity.member_code }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Minimum 3 characters to search -->
                    <div v-if="searchText.length < 3 && !filters.country" class="sl-search-result__palceholder">
                        {{ $t('entities-switch.search-placeholder') }}
                    </div>

                    <!-- No Result -->
                    <div v-else-if="!entities.length" class="sl-search-result__none">
                        {{ $t('entities-switch.no-result') }}
                    </div>
                </perfect-scrollbar>

                <div v-else-if="searchLoading" class="sl-search-result__loading">
                    <img src="/img/loader-5.gif" :alt="$t('common.loading')" class="mx-auto">
                </div>
            </div>

            <!-- Error -->
            <error-view v-else :msg="$t('entities-switch.errors.no_entity_user_link')" />

            <!-- Logout Link -->
            <div class="mt-8 text-primary-500 text-center cursor-pointer">
                <a href="#!" @click.prevent="logout()">
                    {{ $t('common.logout') }}
                </a>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
// todo: add ability to select entity with up and down arrows.

import { debounce, merge } from 'lodash-es';
import axios from '@/axios';
import ErrorView from '@/views/ErrorView.vue';

export default
{
    components:
    {
        ErrorView,
    },

    data()
    {
        return {
            searchText: '',
            filters:
            {
                country: '',
            },
            searchLoading: 0,
            countries:     { ch: 'Switzerland', be: 'Belgium', de: 'Germany' },
        };
    },

    computed:
    {
        entities()
        {
            if(this.$store.state.entitiesCount > this.$const.entities.SWITCHER_THRESHOLD && this.searchText.length <= 2)
            {
                return [];
            }

            return this.$store.state.account.entities;
        },

        hasCountries()
        {
            return !!Object.keys(this.countries).length;
        },
    },

    watch:
    {
        searchLoading(searchLoading)
        {
            if(searchLoading < 0)
            {
                this.searchLoading = 0;
            }
        },
    },

    created()
    {
        // Debounced methods
        this.searchEntities = debounce(this.searchEntities, 400);

        // Load the list of countries where we have customers
        axios.get('/api/entities/countries')
            .then(({ data }) =>
            {
                const countries = {};
                for(const countryCode in data)
                {
                    countries[countryCode] = data[countryCode];
                }

                this.countries = countries;
            })
            .catch(error =>
            {
                // todo: handle error
                throw error;
            });
    },

    async mounted()
    {
        // Force focus on the search input (if it exists)
        this.$refs.searchInput?.focus();

        if(this.$store.state.account.entitiesCount === 1)
        {
            // There is only one entity => select it by fetching its ID from the API
            const response = await axios.get('/api/entities');
            this.$router.replace({
                name:   'home',
                params: {
                    lang:      this.$route.params.lang,
                    entity_id: response.data[0].id,
                },
            });
        }
    },

    methods:
    {
        isSelectable(entity)
        {
            // DEAD or INACTIVE entity cannot be selected.
            if(['INACTIVE', 'DEAD', 'BANNED'].includes(entity.status))
            {
                return false;
            }

            // When current entity is retreived by a "member of" relationship => no access if current entity is not a branch.
            if(entity.relationship_type === 'MEMBER_OF' && entity.subtype !== 'BRANCH')
            {
                return false;
            }

            return true;
        },

        hasRecipientName(entity)
        {
            const recipientName = [entity.recipient_name, entity.recipient_name_affix]
                .filter(s => !!s)
                .join(' - ');

            return recipientName && (recipientName.toLowerCase() !== entity.name.toLowerCase());
        },

        selectEntity(entity)
        {
            if(this.isSelectable(entity))
            {
                this.$router.push({
                    name:   'initializing',
                    params: this.$route.params,
                });

                this.$store.dispatch('initialize', entity.id)
                    .then(() =>
                    {
                        this.$router.replace({
                            name:   'home',
                            params: merge(this.$route.params, { entity_id: entity.id }),
                        });
                    });
            }
        },

        logout()
        {
            this.$store.dispatch('auth/logout');
        },

        selectFirstEntity()
        {
            if(this.entities.length)
            {
                this.selectEntity(this.entities[0]);
            }
        },

        getStatusClass(entity)
        {
            switch(entity.status)
            {
                case 'VALIDATED':
                    return 'sl-entity__status--active';

                case 'IMPORTED':
                    return 'sl-entity__status--active';

                case 'ACTIVE':
                    return 'sl-entity__status--active';

                case 'BLOCKED_ORDER':
                    return 'sl-entity__status--blocked';

                case 'BLOCKED_DELIVERY':
                    return 'sl-entity__status--blocked';

                case 'INACTIVE':
                    return 'sl-entity__status--inactive';

                case 'BANNED':
                    return 'sl-entity__status--banned';

                case 'DEAD':
                    return 'sl-entity__status--closed';
            }

            return null;
        },

        getStatusText(entity)
        {
            // VALIDATED        => active
            // IMPORTED         => active
            // ACTIVE           => active
            // BLOCKED_ORDER    => blocked
            // BLOCKED_DELIVERY => blocked
            // INACTIVE         => inactive
            // DEAD             => closed
            // BANNED           => banned

            return this.$t(`common.entity_status.${entity.status}`);
        },

        onSearchInput()
        {
            if(this.searchText.trim().length > 2 || this.filters.country)
            {
                // Display the loader before calling the debounced searchEntities() method
                // to avoid having a "no result" message before the search actually starts loading.
                this.searchEntities(this.searchText, this.filters);
            }
            else
            {
                this.searchEntities.cancel();
                this.searchLoading -= 1;
                this.$store.dispatch('account/emptySearchResults');
            }
        },

        /**
         * This method is debounced in `created()`.
         * The search text must be a parameter to avoid "400 Bad Request" errors
         * when the search input is changed before the request is sent (which can happen
         * since this method is debounced).
         *
         * @param {String} searchText
         * @param {Object} filters
         * @param {String} [filters.country]
         */
        searchEntities(searchText, filters = {})
        {
            this.searchLoading += 1;

            // First, empty the search results.
            this.$store.dispatch('account/emptySearchResults')
                .then(() =>
                {
                    // Then, perform the search!
                    this.$store.dispatch('account/searchUserEntities', { searchText, filters })
                        .then(entities =>
                        {
                            // nothing
                        })
                        .catch(error =>
                        {
                            // nothing
                        })
                        .then(() =>
                        {
                            // Hide loader.
                            // It should've been manually displayed in the calling function,
                            // because this one is debounced.
                            this.searchLoading -= 1;
                        });
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-entities-switch
{
    width: 600px;
    @apply p-8 relative mx-auto overflow-hidden;
}

.sl-entities-switch__header
{
    @apply py-8;
}

.sl-entities-switch__content
{
    @apply pt-4;
}

.sl-entities-switch__logo
{
    @apply mx-auto;
}


// -------------------- Search Field

.sl-search-input__wrapper
{
    @apply relative;
}

.sl-search-input__wrapper .mdi-icon
{
    @apply absolute top-3 left-0 ml-4 w-6 h-6 text-primary-500;
}

.sl-search-input
{
    @apply mb-4 border rounded-full border-gray-300 px-[50px] py-3 w-full text-gray-600 outline-none appearance-none;
}

.sl-search-input::placeholder
{
    @apply text-center font-light;
}

.sl-search-input__country
{
    @apply absolute top-0 right-[60px] h-full overflow-hidden;

    > select
    {
        @apply cursor-pointer appearance-none
            relative top-0.5
            border-x border-gray-300 px-4 py-3 max-w-48
            bg-transparent text-gray-600 text-center truncate;
    }

    &.sl-search-input__country--searching
    {
        > select
        {
            @apply border-r-0;
        }
    }
}

.sl-search-input__nb-results
{
    @apply absolute top-[0.95rem] right-6 text-gray-400;
}

.sl-search-result__wrapper
{
    @apply relative px-4 overflow-hidden;

    height: calc(100vh - 340px); // determined by trial and error
}

.sl-search-result__palceholder
{
    @apply mt-3 text-center text-gray-500 italic;
}

.sl-search-result__none
{
    @apply mt-3 text-center text-gray-500;
}

/*--- ENTITY ---*/

.sl-entity
{
    @apply cursor-pointer mb-3 border border-gray-300 rounded-lg p-3 bg-white outline-none select-none
        hover:bg-gray-100
        focus:bg-gray-100 focus:border-primary-400;
}

.sl-entity--not-selectable
{
    @apply cursor-default bg-gray-100;
}

.sl-entity__line
{
    @apply flex justify-between items-center;
}

.sl-entity__status-wrapper
{
    @apply ml-1 min-w-[16.66667%] shrink-0;
}

.sl-entity__status
{
    min-width: 60px;
    @apply rounded-full px-3 py-1 text-center text-xs;
}

.sl-entity__status--active
{
    @apply bg-green-100 text-green-400;
}

.sl-entity__status--blocked
{
    @apply bg-yellow-100 text-yellow-500;
}

.sl-entity__status--inactive
{
    @apply bg-gray-100 text-gray-300;
}

.sl-entity__status--closed, .sl-entity__status--banned
{
    @apply bg-red-100 text-red-400;
}

.sl-entity__ext-id
{
    @apply ml-auto w-2/12 text-gray-400 text-sm text-center;
}

.sl-entity__name
{
    @apply text-gray-600;
}

.sl-entity__recipient-name
{
    @apply text-gray-500 text-sm;
}

.sl-entity__address
{
    @apply text-gray-400 text-sm;
}

</style>
