import {
    mdiAccount,
    mdiAccountSwitch,
    mdiAlert,
    mdiAlertBox,
    mdiAlertOutline,
    mdiArrowSplitVertical,
    mdiBasket,
    mdiBellOutline,
    mdiCalculator,
    mdiCalendarArrowLeft,
    mdiCalendarArrowRight,
    mdiCardAccountDetailsOutline,
    mdiChartBellCurve,
    mdiCheck,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCheckboxMarkedOutline,
    mdiChevronRight,
    mdiCircleOffOutline,
    mdiClose,
    mdiCreation,
    mdiCreationOutline,
    mdiDelete,
    mdiDeleteOutline,
    mdiDolly,
    mdiEmailOutline,
    mdiEye,
    mdiEyeOff,
    mdiFilter,
    mdiFilterOutline,
    mdiInformationOutline,
    mdiMagnify,
    mdiMenu,
    mdiMenuDown,
    mdiMinus,
    mdiNewspaperVariantMultipleOutline,
    mdiNinja,
    mdiOpenInNew,
    mdiPencil,
    mdiPhone,
    mdiPlus,
    mdiPlusCircleMultipleOutline,
    mdiRepeat,
    mdiSale,
    mdiStar,
    mdiSwapHorizontalCircleOutline,
    mdiTranslate,
    mdiTriangle,
    mdiUndo,
} from '@mdi/js';

export default Object.freeze({
    mdiAccount,
    mdiAccountSwitch,
    mdiAlert,
    mdiAlertBox,
    mdiAlertOutline,
    mdiArrowSplitVertical,
    mdiBasket,
    mdiBellOutline,
    mdiCalculator,
    mdiCalendarArrowLeft,
    mdiCalendarArrowRight,
    mdiCardAccountDetailsOutline,
    mdiChartBellCurve,
    mdiCheck,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCheckboxMarkedOutline,
    mdiChevronRight,
    mdiCircleOffOutline,
    mdiClose,
    mdiCreation,
    mdiCreationOutline,
    mdiDelete,
    mdiDeleteOutline,
    mdiDolly,
    mdiEmailOutline,
    mdiEye,
    mdiEyeOff,
    mdiFilter,
    mdiFilterOutline,
    mdiInformationOutline,
    mdiMagnify,
    mdiMenu,
    mdiMenuDown,
    mdiMinus,
    mdiNewspaperVariantMultipleOutline,
    mdiNinja,
    mdiOpenInNew,
    mdiPencil,
    mdiPhone,
    mdiPlus,
    mdiPlusCircleMultipleOutline,
    mdiRepeat,
    mdiSale,
    mdiStar,
    mdiSwapHorizontalCircleOutline,
    mdiTranslate,
    mdiTriangle,
    mdiUndo,
});
