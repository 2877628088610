// ------------------------------------------------------------ VUE

import { createApp } from 'vue';


// ------------------------------------------------------------ HONEYBADGER

import { createHoneybadger } from './utils/vue-honeybadger';

const VueHoneybadger = createHoneybadger({
    apiKey: '905baae0',

    // By default, HoneyBadger only reports in the "production" environment
    environment: import.meta.env.MODE,
});


// ------------------------------------------------------------ VUE CONFIGURATIONS

import {
    getApplicationConfigurations,
    getUserConfigurations,
} from './vue-configurations';


// ------------------------------------------------------------ I18N

import { setupI18n } from './i18n';

const i18n = setupI18n();


// ------------------------------------------------------------ STORE

import { setupStore } from './store/mainStore';

const store = setupStore(i18n, VueHoneybadger);


// ------------------------------------------------------------ ROUTER

import { setupRouter } from './router/router';

const router = setupRouter(store);


// ------------------------------------------------------------ VUEX-ROUTER-SYNC

import { sync } from 'vuex-router-sync';

sync(store, router);


// ------------------------------------------------------------ APP CONSTANTS

import constants from './constants/constants';


// ------------------------------------------------------------ STYLE

import './assets/styles/main.scss';

// ------------------------------------------------------------ V-CALENDAR

import '../node_modules/v-calendar/dist/style.css';


// ------------------------------------------------------------ PERFECT SCROLLBAR

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';


// ------------------------------------------------------------ VUE-FORMATTER

import VueFormatter from './utils/vue-formatter';


// ------------------------------------------------------------ VUE-COMPANY-CONTACT

import VueCompanyContact from './utils/vue-company-contact';


// ------------------------------------------------------------ VUE-TIPPY

import VueTippy from 'vue-tippy';


// ------------------------------------------------------------ VUE GTAG

import VueGtag from 'vue-gtag';

const vueGtagOptions = {
    // Enable tracking in the production environment only.
    // This won't even download the GTag library in dev.
    disableScriptLoad: import.meta.env.MODE !== 'production',

    // Define the page tracker template
    pageTrackerTemplate(to)
    {
        return {
            page_title:    to.name,
            page_path:     to.path,
            page_location: window.location.href,
        };
    },

    config:
    {
        // Google Analytics Tracking ID
        id: 'G-RJ1VWDET0C',
    },
};


// ------------------------------------------------------------ VUE-QUILL

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';


// ------------------------------------------------------------ COMPONENTS

import SlLensSide from './components/SlLensSide.vue';


// ------------------------------------------------------------ COMPONENTS & DIRECTIVES

import {
    LsnClickOutside,
    LsnInput,
    LsnInputNumber,
    LsnModal,
    LsnSider,
    LsnSwitch,
} from 'vue-lausanne';

import CanDirective                      from '@/directives/CanDirective';
import IsStaffDirective                  from '@/directives/IsStaffDirective';
import HasActiveArticlesByTagDirective   from '@/directives/HasActiveArticlesByTagDirective';
import HasActivePrototypesByTagDirective from '@/directives/HasActivePrototypesByTagDirective';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';


// ------------------------------------------------------------ APP COMPONENTS

import MdiIcon from '@/components/MdiIcon.vue';


// ------------------------------------------------------------ APP AND STORE

import App from './App.vue';


// ------------------------------------------------------------ APP BUILDING

store.dispatch('auth/check').finally(() =>
{
    const app = createApp(App);

    // Register global constants as `$const`
    app.config.globalProperties.$const = constants;

    app
        .use(store)
        .use(router)
        .use(Toast, { hideProgressBar: true, timeout: 5000, position: 'bottom-right' })
        .use(i18n)
        .use(VueTippy,
            {
                directive: 'tippy', // => v-tippy
                component: 'tippy', // => <tippy />
                defaultProps:
                {
                    arrow:     true,
                    theme:     'sl-light',
                    animation: 'fade',
                },
            })
        .use(VueGtag, vueGtagOptions, router)
        .use(VueHoneybadger)
        .use(PerfectScrollbarPlugin)
        .use(getApplicationConfigurations(), { globalPropertyKey: '$aconfig' })
        .use(getUserConfigurations(), { globalPropertyKey: '$uconfig' })
        .use(VueFormatter, { i18n, store })
        .use(VueCompanyContact, { store })
        .directive('click-outside', LsnClickOutside)
        .directive('can', CanDirective)
        .directive('is-staff', IsStaffDirective)
        .directive('has-active-articles-by-tag', HasActiveArticlesByTagDirective)
        .directive('has-active-prototypes-by-tag', HasActivePrototypesByTagDirective)
        .component('LsnInput', LsnInput)
        .component('LsnInputNumber', LsnInputNumber)
        .component('LsnModal', LsnModal)
        .component('LsnSider', LsnSider)
        .component('LsnSwitch', LsnSwitch)
        .component('SlLensSide', SlLensSide)
        .component('MdiIcon', MdiIcon)
        .component('QuillEditor', QuillEditor);

    // Save asked URL to redirect to it after initialization.
    const redirectTo = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
    const query = {};

    queryParams.forEach((value, key) =>
    {
        query[key] = value;
    });

    query.redirect = redirectTo;

    const route = router.resolve(window.location.pathname);
    const params = { lang: route.params.lang };

    // If redirection URL contains "login" or "initialize" => remove redirect query parameter.
    if(new RegExp('login|initialize').test(window.location.pathname) || window.location.pathname === '/')
    {
        delete query.redirect;
    }

    if(!store.state.auth.authenticated)
    {
        router.push({ name: 'login', params, query });
    }
    else
    {
        router.push({ name: 'initializing', params, query });
    }

    router.isReady()
        .then(() =>
        {
            const _app = app.mount('#app');

            if(import.meta.env.MODE !== 'production')
            {
                // Make the app instance globally available in development environment
                window.app = _app;
            }
        });
});
