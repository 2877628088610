<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="mt-8 mb-12 shadow-md hover:shadow-lg rounded-xl p-12 bg-red-50 text-red-600 transition-shadow">
        <div class="mb-6 text-red-700 text-3xl font-thin">
            {{ $t('common.errors.embarassed') }}
        </div>

        <p>{{ msg }}</p>

        <p>{{ $t('common.please_contact_swisslens') }}</p>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Schweiz
            </div>

            <a :href="getTelHref(getPhone('CH', 'de'))" class="sl-phone__value">
                {{ getPhone('CH', 'de') }}
            </a>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Suisse
            </div>

            <a :href="getTelHref(getPhone('CH', 'fr'))" class="sl-phone__value">
                {{ getPhone('CH', 'fr') }}
            </a>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Deutschland
            </div>

            <a :href="getTelHref(getPhone('DE', 'de'))" class="sl-phone__value">
                {{ getPhone('DE', 'de') }}
            </a>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                France
            </div>

            <a :href="getTelHref(getPhone('FR', 'fr'))" class="sl-phone__value">
                {{ getPhone('FR', 'fr') }}
            </a>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Italia
            </div>

            <div class="w-2/3">
                <a :href="getTelHref(getPhone('IT', 'it'))" class="sl-phone__value">
                    {{ getPhone('IT', 'it') }}
                </a>

                <a :href="getTelHref(getPhone('IT', 'mobile'))" class="sl-phone__value">
                    {{ getPhone('IT', 'mobile') }}
                </a>
            </div>
        </div>

        <div class="sl-phone__line">
            <div class="sl-phone__label">
                Other Countries
            </div>

            <a :href="getTelHref(getPhone())" class="sl-phone__value">
                {{ getPhone() }}
            </a>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { getContact } from '@/utils/vue-company-contact';

export default
{
    name: 'ErrorView',

    props:
    {
        msg:
        {
            type:     String,
            required: true,
        },
    },

    methods:
    {
        getPhone(country = 'default', language = 'default')
        {
            return getContact('phone', country, language)?.cvalue ?? null;
        },

        getTelHref(phone)
        {
            if(!phone)
            {
                return null;
            }

            return 'tel:' + phone.replace('+', '00').replace(/\s/g, '');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
p
{
    @apply mb-4 leading-snug;
}

p:last-of-type
{
    @apply mb-6;
}

.sl-phone__line
{
    @apply flex justify-start items-start mt-2 -mx-4 rounded transition-colors
        hover:bg-red-100;
}

.sl-phone__label
{
    @apply p-4 w-1/3 text-red-700;
}

.sl-phone__value
{
    @apply block p-4 w-2/3
        hover:underline;
}
</style>
