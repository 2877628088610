import { daSag, deg2rad } from '@/utils/Formulas';
import AbstractSurfaceFilter from './AbstractSurfaceFilter';
import Objects from '@/utils/Objects';

/**
 * @typedef {import('@/views/order/lenses/fields/custom/PlotGraphButton.vue').Point} Point
 */

/** @constant {number} kDiaSim The diameter of a simulated cornea. */
const kDiaSim = 11.7;
/** @constant {number} kExSim The eccentricity of a simulated cornea. */
const kExSim = 0.5;

export default class DilationFilter extends AbstractSurfaceFilter
{
    coeff = 1;
    r0 = 0;

    constructor(options = {})
    {
        super(options);
        this.coeff = options.coeff;
        this.r0 = options.r0;
    }

    /**
     * Run the filter.
     *
     * @param {Point[]} points
     * @returns {Point[]}
     */
    run(points)
    {
        // @fixme
        return points.map(([dia, sag]) =>
        {
            let kSagSim;
            // if(Math.abs(dia) < kDiaSim)
            // {
            kSagSim = -(daSag(this.r0, kExSim, 0, dia) * 1000);
            // }
            // else
            // {
            //     kSagSim = -daSag(this.r0, kExSim, 0, kDiaSim) * 1000 + Math.tan(deg2rad(40) * ((dia - kDiaSim) / 2));
            // }

            return [
                dia,
                this.coeff * (sag - kSagSim) + kSagSim,
            ];
        });
    }
}
