export default class ValidateLensSidesConsistency
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        // Get previous order on right side, if any
        const rightOrderLensSide = this.v.sides.right.previousOrder?.article_data?.LENS_SIDE || null;

        // Get previous order on left side, if any
        const leftOrderLensSide = this.v.sides.left.previousOrder?.article_data?.LENS_SIDE || null;

        // If previous right order lens side is defined and different from "right" and "single" (same for left side).
        if( (rightOrderLensSide !== null && rightOrderLensSide == 'left') || (leftOrderLensSide !== null && leftOrderLensSide == 'right') )
        {
            this.v.showChooseSideModal = true;

            return false;
        }

        return true;
    }
}
