export default class UnknownPrototypeError extends Error
{
    prototypeCode;

    constructor(prototypeCode)
    {
        const t = typeof prototypeCode;
        if(t !== 'string')
        {
            throw new TypeError(`prototypeCode must be a string; ${t} given`);
        }

        super(`Unknown prototype: "${prototypeCode}"`);
        this.prototypeCode = prototypeCode;
    }
}
