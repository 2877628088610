// ------------------------------------------------------------ IMPORTS

import Numbers from "@/utils/Numbers";


// ------------------------------------------------------------ CONSTANTS

/**
 * By how long should the update methods be delayed, in milliseconds.
 */
export const debounceDelay = 1000;


// ------------------------------------------------------------ COMPUTED PROPERTIES

/**
 * The quantity of an order line.
 *
 * @returns {Number}
 */
export function quantity()
{
    return parseInt(this.order.quantity);
};

/**
 * The calculated price for an order line, including supplements (if any).
 *
 * @returns {Number}
 */
export function price()
{
    const supplements = this.order.supplements || [];

    return this.quantity * supplements.reduce(
        (carry, supplement) => carry + (supplement.quantity * supplement.price),
        this.order.price
    );
};

/**
 * The base price for an order line, or NaN if that data is unavailable.
 *
 * @returns {Number}
 */
export function basePrice()
{
    const basePrice = parseFloat(this.order.catalog_price);
    if(isNaN(basePrice))
    {
        return basePrice;
    }

    const supplements = this.order.supplements || [];

    return this.quantity * supplements.reduce(
        (carry, supplement) => carry + (supplement.quantity * supplement.price),
        basePrice
    );
};

/**
 * The base price, formatted with 2 decimals and thousand separators.
 *
 * @returns {String}
 */
export function formattedBasePrice()
{
    if(isNaN(this.basePrice))
    {
        console.log('Base price unavailable for ' + this.order.article.code, this.order);

        return NaN;
    }

    return this.$f(this.basePrice, 'decimal:2|thousand');
};

/**
 * The calculated actual discount for a basket/order line, based on its price, base price and quantity.
 *
 * @returns {Number}
 */
export function discount()
{
    if(this.basePrice == 0)
    {
        return 0;
    }

    return 1 - (this.actualPrice / this.basePrice);
};

/**
 * The discount, formatted with 2 decimals and a percent sign.
 *
 * @returns {String}
 */
export function formattedDiscount()
{
    const discount = this.discount || 0;

    return Numbers.round(discount * 100, 1) + '%';
};

/**
 * The special price, if any.
 * The price, otherwise.
 *
 * @returns {Number}
 */
export function actualPrice()
{
    return this.order.special_price ?? this.price;
};

export function formattedActualPrice()
{
    return this.$f(this.actualPrice, 'decimal:2|thousand');
};


// ------------------------------------------------------------ METHODS

/**
 * Get the label for a basket/order line or supplement.
 *
 * @param {{}} line Order or supplement
 * @returns {String}
 */
export function getLabel(line)
{
    return line.article.label || line.article.code || '';
};

export function getPrototypeLabel(line)
{
    const prototypeCode = line.article_data?.PROTOTYPE_CODE || line.article_data?.PROTOTYPE_CODE_LEGACY;

    return this.$store.state.prototypes.prototypes.find(p => p.code === prototypeCode)?.label || prototypeCode;
};

export function getLabelByParameterCode(parameterCode)
{
    return this.$t(`parameters.${parameterCode}.label`);
};

export function getUnitByParameterCode(parameterCode)
{
    return this.$store.getters['parameters/getAttribute'](parameterCode, 'unit');
}

/**
 * Get the help message for a parameter's value,
 * or `null` if there is none.
 *
 * @param {string} code The code of the parameter.
 * @param {*} value     The value of the parameter.
 * @returns {?string}
 */
export function getHelp(code, value)
{
    const tkey = `parameters.${code}.values.${value}.help`;
    const help = this.$t(tkey);
    if(help === tkey)
    {
        return null;
    }

    return help;
}
