// =================
// Parameters Module
// =================

import axios from '@/axios';

export function setupParametersModule(i18n)
{
    return {
        namespaced: true,

        // ------------------------------------------------------------------------- STATE

        state:
        {
            propertiesByPrototype: {},
            attributesByParameter: {},
        },

        // ------------------------------------------------------------------------- GETTERS

        getters:
        {
            getFormatters: state => parameterCode => state.attributesByParameter[parameterCode]?.formatters || null,

            getAttribute: state => (parameterCode, attributeCode) =>
            {
                let attributes = state.attributesByParameter[parameterCode];

                if(attributes)
                {
                    return attributes[attributeCode];
                }

                return null;
            },

            getProperty: state => (prototypeCode, parameterCode, propertyCode) =>
            {
                let parameters = state.propertiesByPrototype[prototypeCode];

                if(parameters)
                {
                    let parameter = parameters[parameterCode];

                    if(parameter)
                    {
                        return parameter[propertyCode];
                    }
                }

                return null;
            },
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            setProperties(state, propertiesByPrototype)
            {
                state.propertiesByPrototype = propertiesByPrototype;
            },

            setAttributes(state, attributesByParameter)
            {
                state.attributesByParameter = attributesByParameter;
            },

            setTranslations(state, translations)
            {
                i18n.global.mergeLocaleMessage(i18n.global.locale.value, { parameters: translations });
            },
        },

        // ------------------------------------------------------------------------- MUTATIONS

        actions:
        {
            fetchGlobalProperties({ commit, rootState })
            {
                const url = '/api/parameters/properties/:eid'
                    .replace(':eid', rootState.account.cEntity.id);

                return axios.get(url)
                    .then(response =>
                    {
                        commit('setProperties', response.data);
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },

            fetchAttributes({ commit })
            {
                return axios.get('/api/parameters/attributes')
                    .then(response =>
                    {
                        commit('setAttributes', response.data);
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },

            fetchTranslations({ commit })
            {
                return new Promise((resolve, reject) =>
                {
                    axios.get('/api/parameters/translations')
                        .then(response =>
                        {
                            commit('setTranslations', response.data.parameters);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },
        },
    };
};
