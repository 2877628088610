export default class SetLensPairId
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const uid = Math.floor(Date.now() / 1000) + '' + Math.floor(Math.random() * 10);
        const lensPairId = uid.toString();
        const rightFitting = this.v.$store.getters['lensOrder/getFitting']('right');
        const leftFitting  = this.v.$store.getters['lensOrder/getFitting']('left');

        if(rightFitting && leftFitting)
        {
            console.log(`Lens pair ID ${lensPairId} has been set on both sides`);

            // We add "A" and "B" to lens pair id to distinguish side (necessary when quantity is more than 1)
            rightFitting.getPrototype().setValue('LENS_PAIR_ID', lensPairId + 'A');
            leftFitting.getPrototype().setValue('LENS_PAIR_ID', lensPairId + 'B');
        }
        else if(rightFitting)
        {
            console.log('Lens pair ID has been reset on right side');
            rightFitting.getPrototype().setValue('LENS_PAIR_ID', null);
        }
        else if(leftFitting)
        {
            console.log('Lens pair ID has been reset on left side');
            leftFitting.getPrototype().setValue('LENS_PAIR_ID', null);
        }

        return true;
    }
}
