import { has, get } from 'lodash-es';

// ------------------------------------------------------------ DATA

const SWISSLENS = {
    address:
    {
        default:
        {
            type:                 'HEADQUARTER',
            recipient_name:       'SwissLens SA',
            recipient_name_affix: null,
            fao:                  null,
            line1:                'Chemin des Creuses 9',
            line2:                null,
            line3:                null,
            line4:                null,
            po_box:               null,
            postcode:             '1008',
            city:                 'Prilly',
            county:               'VD',
            country:              'CH',
        },
    },

    phone:
    {
        default:
        {
            default:
            {
                type:   'PHONE',
                cvalue: '+41 21 620 06 66',
            },

            // Austria (use the German one)
            AT:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+49 5464 9789997',
                },
            },

            // Switzerland
            CH:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+41 21 620 06 66',
                },
                fr:
                {
                    type:   'PHONE',
                    cvalue: '+41 21 620 06 67',
                },
                de:
                {
                    type:   'PHONE',
                    cvalue: '+41 21 620 06 68',
                },
                it:
                {
                    type:   'PHONE',
                    cvalue: '+41 21 620 06 69',
                },
            },

            // Germany
            DE:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+49 5464 9789997',
                },
            },

            // Spain
            ES:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+34 919 01 21 13',
                },
            },

            // France (same as Reunion)
            FR:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+33 4 88 60 36 39',
                },
            },

            // Italy
            IT:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+39 041 804 00 45',
                },
                mobile:
                {
                    type:   'PHONE',
                    cvalue: '+39 347 600 05 35',
                },
            },

            // United Kingdom of Great Britan and Northern Ireland
            GB:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+44 2033 183023',
                },
            },

            // Reunion (same as France)
            RE:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+33 4 88 60 36 39',
                },
            },

            // Poland
            PL:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+48 583 29 93 50',
                },
            },

            // Portugal (same as Spain)
            PT:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+34 919 01 21 13',
                },
            },

            // Ukraine
            UA:
            {
                default:
                {
                    type:   'PHONE',
                    cvalue: '+380 89 320 23 00',
                },
            },
        },
    },

    email:
    {
        default:
        {
            default:
            {
                type:   'EMAIL',
                cvalue: 'lens.info@swisslens.ch',
            },
        },
    },
};


// ------------------------------------------------------------ HELPERS

/**
 * Get the contact
 * @param {"address"|"phone"|"email"} type
 * @param {string} [country]
 * @param {string} [language]
 * @param {string} [context]
 * @returns {?string}
 */
export function getContact(type, country = 'default', language = 'default', context = 'default')
{
    const contactsByCompany = {
        SWISSLENS,
    };

    const contactsByType = contactsByCompany.SWISSLENS[type];
    const keys = [
        [context, country, language],
        [context, country, 'default'],
        [context, 'default'],
        ['default', country, language],
        ['default', country, 'default'],
        ['default', 'default'],
        ['default'],
    ];

    for(const key of keys)
    {
        if(has(contactsByType, key))
        {
            return get(contactsByType, key);
        }
    }

    return null;
};

export default
{
    install(app, { store })
    {
        // ------------------------------------------------------------ METHODS

        app.config.globalProperties.$cc = {
            address(context = 'default')
            {
                const cEntity = store.state.account.cEntity;

                return getContact('address', cEntity.country, cEntity.lng, context);
            },

            phone(context = 'default')
            {
                const cEntity = store.state.account.cEntity;

                return getContact('phone', cEntity.country, cEntity.lng, context).cvalue;
            },

            email(context = 'default')
            {
                const cEntity = store.state.account.cEntity;

                return getContact('email', cEntity.country, cEntity.lng, context).cvalue;
            },
        };
    },
};
