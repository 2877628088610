// ==============
// Account Module
// ==============

import axios     from '@/axios';
import constants from '@/constants/constants';
import { getUserConfigurations } from '@/vue-configurations';

const $uconfig = getUserConfigurations();

export function setupAccountModule(VueHoneybadger)
{
    return {
        namespaced: true,

        // ------------------------------------------------------------------------- STATE

        state()
        {
            return {
                cUser:                         null,  // Connected user
                entities:                      [],    // Entities that user has access to (filtered by search if more than 10; all of them otherwise)
                entitiesCount:                 0,     // How many entities the user has access to (unfiltered)
                cEntity:                       null,  // Current selected entities
                searchAbortController:         new AbortController, // AbortController to avoid having multiple simultaneous search requests
                entityInternalNotePanelIsOpen: false, // Whether the "Internal Note" panel is open
            };
        },

        // ------------------------------------------------------------------------- GETTERS

        getters:
        {
            /**
             * Helper for determining whether the current user has one or a set of permissions.
             * Usage examples:
             *  - `$store.getters['account/can']('price:edit')`
             *  - `$store.getters['account/can'](['invoice:view', 'price:edit'])`
             */
            can: state => permissions =>
            {
                if(!Array.isArray(permissions))
                {
                    permissions = [permissions];
                }

                return permissions.every(p => !!state.cUser?.permissions?.includes(p));
            },

            isStaff:         state => ['DEVELOPER', 'INTERNAL_STAFF', 'EXTERNAL_STAFF'].includes(state.cUser.role),
            isInternalStaff: state => ['DEVELOPER', 'INTERNAL_STAFF'].includes(state.cUser.role),
            isDeveloper:     state => state.cUser.role === 'DEVELOPER',
        },

        // ------------------------------------------------------------------------- MUTATIONS

        mutations:
        {
            /**
             * Set connected user.
             *
             * @param {Object} state
             * @param {User}   cUser
             */
            setCurrentUser(state, cUser)
            {
                console.groupCollapsed('Connected User');
                console.log(cUser);
                console.groupEnd();

                state.cUser = cUser;

                VueHoneybadger.setContext({ user: state.cUser });
            },

            /**
             * Set search results (entities).
             *
             * @param {Object} state
             * @param {Array}  entities
             */
            setCurrentUserEntities(state, entities)
            {
                state.entities = entities;
            },

            /**
             * Set connected user entities count.
             *
             * @param {Object} state
             * @param {Number} count
             */
            setCurrentUserEntitiesCount(state, count)
            {
                if(typeof count !== 'number')
                {
                    VueHoneybadger.setContext({ count });

                    throw new TypeError(`count must be a number (got ${typeof count})`);
                }
                if(count < 0)
                {
                    VueHoneybadger.setContext({ count });

                    throw new RangeError(`count must be a non-negative number (got ${count})`);
                }

                state.entitiesCount = count;
            },

            /**
             * Instantiate a new AbortController for canceling search requests.
             *
             * @param {Object} state
             */
            abortSearchRequest(state)
            {
                state.searchAbortController.abort();
                state.searchAbortController = new AbortController();
            },

            /**
             * Set current entity.
             *
             * @param {Object} state
             * @param {User}   cUser
             */
            setCurrentEntity(state, entity)
            {
                console.groupCollapsed('Current Entity');
                console.log(entity);
                console.groupEnd();

                state.cEntity = entity;

                VueHoneybadger.setContext({ entity: state.cEntity });
            },

            setCurrentEntityInternalNote(state, internalNote)
            {
                state.cEntity.internal_note = internalNote;
            },

            setEntityInternalNotePanelIsOpen(state, isOpen)
            {
                state.entityInternalNotePanelIsOpen = !!isOpen;
            },

            /* ---------- user configurations ---------- */

            /**
             * Set global user configurations (from app_config table).
             *
             * @param {Object} state
             * @param {Object} configurations
             */
            setUserConfigurations(state, configurations)
            {
                $uconfig.initialize(configurations);
            },
        },

        // ------------------------------------------------------------------------- ACTIONS

        actions:
        {
            /* ---------- connected user ---------- */

            fetchConnectedUser({ commit, dispatch })
            {
                return axios.get('/api/user/connected')
                    .then(response =>
                    {
                        // Prevent resetting the language
                        if($uconfig.has('language'))
                        {
                            response.data.configurations.language = $uconfig.get('language');
                        }

                        commit('setUserConfigurations', response.data.configurations);
                        delete response.data.configurations; // avoid having configurations in cUser object

                        commit('setCurrentUser', response.data);

                        // Listen for "set" event and update user configuration accordingly (server side)
                        $uconfig._configurations.on('set', function(key, value)
                        {
                            dispatch('updateUserConfigurations', {
                                ckey:   key,
                                cvalue: value,
                            });
                        });
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },

            /* ---------- entities ---------- */

            /**
             * Retrieve all entities the current user has access to.
             */
            fetchUserEntities({ commit, state })
            {
                return new Promise((resolve, reject) =>
                {
                    const threshold = constants.entities.SWITCHER_THRESHOLD;
                    if(state.entitiesCount < 1)
                    {
                        throw new RangeError('No entities to load');
                    }
                    if(state.entitiesCount > threshold)
                    {
                        throw new RangeError(`Too many entities to load (${state.entitiesCount}/${threshold})`);
                    }

                    axios.get('/api/entities')
                        .then(response =>
                        {
                            commit('setCurrentUserEntities', response.data);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error.response?.errors || error.message || error);
                            reject(error);
                        });
                });
            },

            fetchUserEntitiesCount({ commit })
            {
                return new Promise((resolve, reject) =>
                {
                    axios.get(`/api/entities/count`)
                        .then(response =>
                        {
                            commit('setCurrentUserEntitiesCount', response.data);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error.response?.errors || error.message || error);
                            reject(error);
                        });
                });
            },

            searchUserEntities({ commit, state }, { searchText, filters = {} })
            {
                return new Promise((resolve, reject) =>
                {
                    // Cancel previous request, if any
                    commit('abortSearchRequest');

                    const options = {
                        signal: state.searchAbortController.signal,
                        params: { q: searchText },
                    };

                    if(filters.country)
                    {
                        options.params.country = filters.country;
                    }

                    axios.get(`/api/entities/search`, options)
                        .then(response =>
                        {
                            commit('setCurrentUserEntities', response.data);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error.response?.errors || error.message || error);
                            reject(error);
                        });
                });
            },

            emptySearchResults({ commit })
            {
                commit('setCurrentUserEntities', []);
                return Promise.resolve([]);
            },

            fetchCurrentEntity({ commit, dispatch }, eid)
            {
                return new Promise((resolve, reject) =>
                {
                    const url = '/api/entity/:eid'
                        .replace(':eid', eid);

                    axios.get(url)
                        .then(({ data: entity }) =>
                        {
                            commit('setCurrentEntity', entity);

                            // todo: Consider using a Chain of Responsibility pattern for the following operations...

                            // Display a notice if the current entity is blocked for new orders
                            if(entity.status === 'BLOCKED_ORDER')
                            {
                                const notification = {
                                    id:          'BLOCKED_ORDER',
                                    dismissible: false,
                                    theme:       'danger',
                                    title:       'invoicing.notice.dispute.title',
                                    text:        'invoicing.notice.dispute.text',
                                };

                                dispatch('notifications/addBandNotification', notification, { root: true });
                            }
                            else
                            {
                                // Remove it otherwise
                                dispatch('notifications/removeBandNotification', 'BLOCKED_ORDER', { root: true });
                            }

                            // Make sure the "Entity Internal Note" panel is closed
                            dispatch('toggleEntityInternalNotePanel', false);

                            resolve(entity);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },

            /**
             * Change the open/closed state of the entity's internal note panel.
             *
             * @param {*} param0
             * @param {bool|undefined} isOpen `true` to open, `false` to close, `undefined` to toggle.
             */
            toggleEntityInternalNotePanel({ commit, state }, isOpen = void 0)
            {
                if(typeof isOpen === 'undefined')
                {
                    isOpen = !state.entityInternalNotePanelIsOpen;
                }

                commit('setEntityInternalNotePanelIsOpen', isOpen);
            },

            fetchEntityInternalNote({ commit, state })
            {
                return new Promise((resolve, reject) =>
                {
                    const eid = state.cEntity.id;
                    axios.get(`/api/entity/${eid}/internal-note`)
                        .then(({ data: internalNote }) =>
                        {
                            commit('setCurrentEntityInternalNote', internalNote);
                            resolve(internalNote);
                        })
                        .catch(error =>
                        {
                            reject(error);
                        });
                });
            },

            saveEntityInternalNote({ commit, state }, internalNote)
            {
                return new Promise((resolve, reject) =>
                {
                    const eid = state.cEntity.id;
                    axios.post(`/api/entity/${eid}/internal-note`, { internal_note: internalNote })
                        .then(({ data: receivedInternalNote }) =>
                        {
                            commit('setCurrentEntityInternalNote', receivedInternalNote.pvalue);
                            resolve(receivedInternalNote);
                        })
                        .catch(error =>
                        {
                            reject(error);
                        });
                });
            },

            /* ---------- user configurations ---------- */

            updateUserConfigurations({}, configuration)
            {
                return axios.post('/api/user-configuration', configuration)
                    .then(response =>
                    {
                        // nothing
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            },
        },
    };
};
