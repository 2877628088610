export default
{
    round(num, decimals)
    {
        const e = Math.pow(10, parseInt(decimals) || 0);

        return Math.round((num + Number.EPSILON) * e) / e;
    },

    roundToMultiple(num, multiple)
    {
        return Math.round(num / multiple) * multiple;
    },

    ceilToMultiple(num, multiple)
    {
        return Math.ceil(num / multiple) * multiple;
    },

    floorToMultiple(num, multiple)
    {
        return Math.floor(num / multiple) * multiple;
    },
};
