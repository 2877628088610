// ------------------------------------------------------------ IMPORTS

import Numbers from '@/utils/Numbers';


// ------------------------------------------------------------ OBJECTS SHARED WITH ORDER TABLE

export {
    // Constants
    debounceDelay,

    // Computed Properties
    discount,
    formattedActualPrice,
    formattedDiscount,

    // Methods
    getLabel,
    getPrototypeLabel,
    getLabelByParameterCode,
    getUnitByParameterCode,
    getHelp,
} from '@/shared/OrderTableSharedObjects';


// ------------------------------------------------------------ COMPUTED PROPERTIES

/**
 * The quantity of a basket line.
 *
 * @returns {Number}
 */
export function quantity()
{
    return parseInt(this.basketLine.quantity);
};

/**
 * The calculated price for a basket line, including supplements (if any).
 *
 * @returns {Number}
 */
export function price()
{
    const supplements = this.basketLine.supplements || [];

    return this.quantity * supplements.reduce(
        (carry, supplement) => carry + (supplement.quantity * supplement.price),
        this.basketLine.price
    );
};

/**
 * The base price for a basket line, or NaN if that data is unavailable.
 *
 * @returns {Number}
 */
export function basePrice()
{
    const basePrice = parseFloat(this.$store.getters['catalog/getByCode'](this.basketLine.article.code)?.base_price);
    if(isNaN(basePrice))
    {
        return basePrice;
    }

    const supplements = this.basketLine.supplements || [];

    return this.quantity * supplements.reduce(
        (carry, supplement) => carry + (supplement.quantity * supplement.price),
        basePrice
    );
};

/**
 * The base price, formatted with 2 decimals and thousand separators.
 *
 * @returns {String}
 */
export function formattedBasePrice()
{
    if(isNaN(this.basePrice))
    {
        console.log('Base price unavailable for ' + this.basketLine.article.code, this.basketLine);

        return NaN;
    }

    return this.$f(this.basePrice, 'decimal:2|thousand');
};

/**
 * The special price, if any, or the price.
 *
 * @returns {Number}
 */
export function actualPrice()
{
    return this.basketLine.special_price ?? this.price;
};


// ------------------------------------------------------------ METHODS

/**
 * Update the value displayed in the "price" field to match that of the order/basket line.
 */
export function matchEditableFieldValuesWith(prop)
{
    if(this.$store.getters['account/can']('price:edit'))
    {
        this.priceField    = (prop.special_price ?? this.price).toFixed(2);
        this.discountField = Numbers.round(this.discount * 100, 1);
    }

    this.extRefField = prop.extra?.ext_ref || '';
};

/**
 * Update the quantity in the basket.
 * This method should be debounced in `created()`.
 */
export function updateQuantity(newQuantity, line, fieldRef)
{
    // Validate new quantity
    newQuantity = parseInt(newQuantity) || 1;
    if(newQuantity < 1 || newQuantity == line.quantity)
    {
        return;
    }

    // Update quantity in basket line
    line.quantity = newQuantity;

    // Actually update the basket
    updateBasketLine.call(this, line, fieldRef);
};

export function updateDiscount(newDiscount, line, fieldRef)
{
    // Validate new discount
    newDiscount = parseFloat(newDiscount);
    if(isNaN(newDiscount) || newDiscount < 0)
    {
        newDiscount = 0;
    }
    if(newDiscount > 100)
    {
        newDiscount = 100;
    }

    // Update the displayed discount (rounded to 1 decimal)
    this[fieldRef] = Numbers.round(newDiscount, 1);

    // Calculate the new price & update it
    const newPrice = this.basePrice * (1 - newDiscount / 100);
    this.updatePrice(newPrice, line, fieldRef);
}

export function updatePrice(newPrice, line, fieldRef)
{
    // Validate new price
    newPrice = parseFloat(newPrice);

    if(isNaN(newPrice))
    {
        // If the user cleared the price field,
        // reset the special price.
        newPrice = null;
    }

    if(newPrice < 0)
    {
        // If the new price is negative, reset it.
        // We don't want a special price to be lower than zero.
        newPrice = null;
    }

    // Update basket line, marking the price as special
    line.special_price = newPrice;

    // Actually update the basket
    updateBasketLine.call(this, line, fieldRef);
};

export function updateExtRef(newExtRef, line, fieldRef)
{
    // Validate ext_ref length
    if(newExtRef.length > 18)
    {
        newExtRef = newExtRef.substr(0, 18);
    }

    // Update basket line, marking the price as special
    if(!line.extra)
    {
        line.extra = {
            ext_ref: newExtRef,
        };
    }
    else
    {
        line.extra.ext_ref = newExtRef;
    }

    // Actually update the basket
    updateBasketLine.call(this, line, fieldRef);
};

/**
  * Actually update a basket line, then focus the specified field.
  */
function updateBasketLine(line, fieldRef)
{
    // Memorize the price displayed in the field, in case anything goes wrong
    const specialPriceBeforeUpdate = this.priceField;

    // Update displayed price
    this.priceField = line.special_price;

    // Update displayed reference, if any
    if(typeof this.extRefField !== 'undefined')
    {
        this.extRefField = line.extra?.ext_ref;
    }

    this.isUpdating = true;
    this.$store.dispatch('basket/saveLines', line)
        .then(lines =>
        {
            // nothing
        })
        .catch(error =>
        {
            console.log(error);

            // Reset the price displayed in the field
            this.priceField = specialPriceBeforeUpdate;
            line.special_price = specialPriceBeforeUpdate;
        })
        .then(() =>
        {
            this.isUpdating = false;

            if(this.$refs[fieldRef])
            {
                this.$refs[fieldRef].focus();
            }
        });
}
