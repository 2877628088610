<template>
    <div class="sl-fitting-selector">
        <!-- Title -->
        <div class="sl-widget__header">
            <div class="sl-widget__title">
                {{ t('fitting_selector.title') }}
            </div>
        </div>

        <!-- Body -->
        <div class="sl-widget__body">
            <div class="grid grid-cols-2 gap-2">
                <div
                    v-for="side in ['right', 'left']"
                    :key="side"
                    class="sl-fitting-selector__side"
                    :class="`sl-fitting-selector__side--${side}`"
                >
                    <div class="mb-2 text-primary-500">
                        {{ t(`common.${side}`) }}
                    </div>

                    <!-- Fittings -->
                    <div class="">
                        <div v-if="empty[side]" class="py-1 text-gray-500 italic">
                            {{ t('fitting_selector.no_lenses') }}
                        </div>

                        <div
                            v-for="(fitting, id) in fittings[side]"
                            :key="side[0] + id"
                            class="sl-fitting-selector__lens group"
                            :class="{
                                [`sl-fitting-selector__lens--${id}`]: true,
                                [`sl-fitting-selector__lens--${side}`]: true,
                                [`sl-fitting-selector__lens--current`]: fittings.current[side] === id,
                                [`sl-fitting-selector__lens--empty`]: !fitting,
                            }"
                        >
                            <!-- Color -->
                            <div
                                class="sl-fitting-selector__color"
                                :class="`sl-fitting-selector__color--${id}`"
                            >
                            </div>

                            <!-- Name -->
                            <div class="sl-fitting-selector__name" @click="select(side, id)">
                                {{ t('common.lens') }}
                                {{ getLensLetter(id) }}
                            </div>

                            <!-- Spacer -->
                            <div class="grow"></div>

                            <!-- Copy Button -->
                            <tippy :content="canDuplicate[side] ? t('common.duplicate') : `max. ${MAX_FITTINGS}`" :tag="null">
                                <div
                                    class="sl-fitting-selector__copy"
                                    :class="{ 'sl-fitting-selector__copy--disabled': !canDuplicate[side] }"
                                    @click="duplicate(side, id)"
                                >
                                    <mdi-icon icon="mdiPlusCircleMultipleOutline" class="w-3 h-3" />
                                </div>
                            </tippy>

                            <!-- Delete Button -->
                            <div
                                v-if="id >= 2"
                                v-tippy
                                :content="t('common.remove')"
                                class="sl-fitting-selector__delete"
                                @click="remove(side, id)"
                            >
                                <mdi-icon icon="mdiDeleteOutline" class="w-3 h-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="sl-widget__actions">
            <div
                class="sl-widget__button sl-widget__button--white"
                tabindex="0"
                @click="close"
                @keypress.enter="close"
            >
                {{ $t('common.close') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import useFittingComposable from '@/composables/FittingComposable';


// ------------------------------------------------------------ CONSTANTS

// Cannot have more fittings per side
const MAX_FITTINGS = 3;


// ------------------------------------------------------------ DATA

// ...


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = useStore();
const {
    getLensLetter,
} = useFittingComposable();


// ------------------------------------------------------------ COMPUTED

const fittings = computed(() => store.state.lensOrder.fittings);

const empty = computed(() => ({
    right: !fittings.value.right.filter(a => !!a).length,
    left:  !fittings.value.left.filter(a => !!a).length,
}));

const canDuplicate = computed(() => ({
    right: fittings.value.right.length <= MAX_FITTINGS,
    left:  fittings.value.left.length <= MAX_FITTINGS,
}));


// ------------------------------------------------------------ EMITS

const emit = defineEmits(['close']);


// ------------------------------------------------------------ METHODS

function close()
{
    emit('close');
}

function select(side, id)
{
    if(fittings.value.current[side] === id)
    {
        return;
    }

    store.commit('lensOrder/setCurrent', { side, id });
    store.dispatch('lensOrder/template', { side });
}

function duplicate(side, id)
{
    if(!canDuplicate.value[side])
    {
        return;
    }

    store.dispatch('lensOrder/cloneFitting', { side, id })
        .then(newId =>
        {
            store.commit('lensOrder/setCurrent', { side, id: newId });
        });
}

function remove(side, id)
{
    // Cannot remove lenses 0 & 1
    if(id <= 1)
    {
        return;
    }

    store.dispatch('lensOrder/removeFitting', { side, id });
}
</script>
