<template>
    <lsn-table :data="monthlySummaries" class="sl-open-monthly-summaries">
        <template #subtable-caption>
            {{ $t("invoicing.monthly_summaries.table.title") }}
        </template>

        <!-- Header -->
        <template #header>
            <lsn-table-cell class="sl-monthly-summary__date">
                {{ $t("invoicing.table.headers.date") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-monthly-summary__total">
                {{ $t("invoicing.table.headers.total_excl_taxes") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-monthly-summary__currency">
                {{ $t("invoicing.table.headers.currency") }}
            </lsn-table-cell>

            <lsn-table-cell class="sl-monthly-summary__actions" />
        </template>

        <template #summary="{ item }">
            <open-monthly-summary-line-summary :monthly-summary="item" />
        </template>

        <template #details="{ item }">
            <invoice-line-details :invoice="item" />
        </template>
    </lsn-table>
</template>

<script>
import LsnTable from '@/components/LsnTable/LsnTable.vue';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import OpenMonthlySummaryLineSummary from '@/components/monthly-summary/OpenMonthlySummaryLineSummary.vue';
import InvoiceLineDetails from '@/components/invoice/InvoiceLineDetails.vue';

export default{
    name: 'OpenMonthlySummaryTable',

    components:
    {
        LsnTable,
        LsnTableCell,
        OpenMonthlySummaryLineSummary,
        InvoiceLineDetails,
    },

    props:
    {
        monthlySummaries:
        {
            type:     Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.sl-open-monthly-summaries
{
    --column-width: 140px;
    --small-column-width: 100px;
}

.lsn-table.lsn-table--openable.sl-open-monthly-summaries > .lsn-table__header,
.lsn-table.lsn-table--openable.sl-open-monthly-summaries > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns:
        var(--open-toggle-width)  // chevron
        var(--column-width)       // date
        var(--column-width)       // amount
        var(--small-column-width) // currency
        auto;                     // empty space
}

.lsn-table:not(.lsn-table--openable).sl-open-monthly-summaries > .lsn-table__header,
.lsn-table:not(.lsn-table--openable).sl-open-monthly-summaries > .lsn-table__body > .lsn-table__line > .lsn-table__line-summary
{
    display: grid;
    grid-template-columns:
        var(--column-width)       // date
        var(--column-width)       // amount
        var(--small-column-width) // currency
        auto;                     // empty space
}
</style>
