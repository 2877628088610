import axios from '@/axios';
import Order from '@/models/Order';
import LensOrderUrlManager from '../LensOrderUrlManager';

export default class FetchPreviousOrderByLensSn
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        /** @type {LensOrderUrlManager} */
        const lensOrderUrlManager = this.v.lensOrderUrlManager;

        // Skip unless there's a lens serial number in the URL
        if(!lensOrderUrlManager.hasLensSn(this.side))
        {
            console.log(`No lens SN in URL for ${this.side} side`);

            return true;
        }

        const urlOrderIdentifier = lensOrderUrlManager.getLensSn(this.side);
        console.log(`There is a lens SN in URL for ${this.side} side: ${urlOrderIdentifier}`);

        const previousOrder = this.v.sides[this.side].previousOrder;
        const previousOrderIdentifier = previousOrder?.getArticleDataAttribute('LENS_SERIAL_NUMBER') || null;

        // Check that URL lens SN is different from current previous order lens SN.
        if(!previousOrder || urlOrderIdentifier != previousOrderIdentifier)
        {
            console.log(`Lens SN is set or has changed in URL for ${this.side} side`);

            return axios.get(`/api/order/lens-sn/${urlOrderIdentifier}`)
                .then(response =>
                {
                    this.v.sides[this.side].previousOrder = new Order(response.data);

                    return true;
                })
                .catch(err =>
                {
                    this.v.sides[this.side].previousOrder = null;
                    this.v.sides[this.side].error = this.v.$t('order.renewal_form.error.not_found');

                    return false;
                });
        }
        else
        {
            console.log(`Lens SN in URL for ${this.side} side is the same as in last dispatch`);
        }

        return true;
    }
}
