<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <!-- RBS stands for "Reset Back Surface" -->
                <div class="sl-order-form__rbs">
                    <div
                        class="lsn-btn lsn-btn--fitting"
                        :class="`lsn-btn--fitting-${fittings.current[side]}`"
                        :tabindex="tabIndex"
                        @click.prevent="resetBackSurface"
                        @keypress.enter="resetBackSurface"
                    >
                        <mdi-icon icon="mdiUndo" class="inline-block w-5 h-5" />

                        <span class="inline-block ml-2">
                            {{ $t('parameters.RESET_BACK_SURFACE_BUTTON.button_label') }}
                        </span>

                        <span v-if="fittings[side].length > 2" class="ml-1">
                            ({{ getLensLetter(fittings.current[side]) }})
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Parameter from '@/models/Parameter';
import AbstractField from '../AbstractField.vue';
import useFittingComposable from '@/composables/FittingComposable';

/**
 * All the parameters in these sections are reset upon clicking the button.
 *
 * @type {string[]}
 */
const SECTION_CODES = [
    'BACK_OPTICAL_ZONE',
    'LIMBAL_ZONE',
    'SCLERAL_ZONE',
    'PERIPHERAL_ZONE',
    'MERIDIAN_1',
    'MERIDIAN_2',
    'MERIDIAN_3',
    'MERIDIAN_4',
];

/**
 * All these parameters are reset upon clicking the button.
 *
 * @type {string[]}
 */
const PARAMETER_CODES = [
    'LENS_EDGE_RADIUS',
];

export default
{
    name: 'ResetBackSurfaceButton',

    extends: AbstractField,

    setup()
    {
        const { getLensLetter } = useFittingComposable();

        return {
            getLensLetter,
        };
    },

    computed:
    {
        fittings()
        {
            return this.$store.state.lensOrder.fittings;
        },

        /**
         * The visible parameters that must be reset when clicking the button.
         *
         * @returns {Parameter[]}
         */
        visibleBackSurfaceParameters()
        {
            /** @type {Parameter[]} */
            const params = this.$store.getters['lensOrder/getPrototype'](this.side).getParametersAsArray();

            return params.filter(p =>
            {
                // Exclude invisible parameters
                if(
                    !p.is_enabled
                    || p.form_display === 'HIDDEN'
                    || p.form_splitted_display === 'HIDDEN'
                )
                {
                    return false;
                }

                // Include select parameters
                if(PARAMETER_CODES.includes(p.code))
                {
                    return true;
                }

                // Include parameters in select sections
                if(SECTION_CODES.includes(p.section))
                {
                    return true;
                }

                return false;
            });
        },
    },

    methods:
    {
        resetBackSurface()
        {
            for(const param of this.visibleBackSurfaceParameters)
            {
                param.setValue(param.getDefaultValue());
            }

            this.$store.dispatch('lensOrder/templateAndValidate', { side: this.side });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
