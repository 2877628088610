export default class SetPrototypesSide
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const rightFitting = this.v.$store.getters['lensOrder/getFitting']('right');
        if(rightFitting)
        {
            rightFitting.getPrototype().setValue('LENS_SIDE', 'right');
            console.log('Right prototype side has been set ("LENS_SIDE" parameter)');
        }

        const leftFitting = this.v.$store.getters['lensOrder/getFitting']('left');
        if(leftFitting)
        {
            leftFitting.getPrototype().setValue('LENS_SIDE', 'left');
            console.log('Left prototype side has been set ("LENS_SIDE" parameter)');
        }

        return true;
    }
}
