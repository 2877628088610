import axios from '@/axios';
import {
    computed,
    ref,
    toValue,
    watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import LensOrderUrlManager from '@/views/order/lenses/LensOrderUrlManager';

export default function usePatientReferenceComposable(props, data)
{
    // ------------------------------------------------------------ COMPOSABLES

    const store  = useStore();
    const router = useRouter();
    const route  = useRoute();
    const { t }  = useI18n({ useScope: 'global' });


    // ------------------------------------------------------------ CONSTANTS

    // ...


    // ------------------------------------------------------------ DATA

    const reorderConfirmationModalData = ref([]);
    const reorderConfirmationModalOpen = ref(false);
    const prototypeSelectionModalOpen  = ref(false);
    const lensOrders = ref([]);


    // ------------------------------------------------------------ COMPUTED

    const tabIndex = computed(() =>
    {
        return toValue(props.side) === 'left' ? 2 : 1;
    });

    const cPrototype = computed(() =>
    {
        const cSide = toValue(props.side);

        return cSide === 'both'
            ? store.getters['lensOrder/getPrototype']('left')
            : store.getters['lensOrder/getPrototype'](cSide);
    });

    const cPrototypes = computed(() =>
    {
        const side = toValue(props.side);
        if(side !== 'both')
        {
            return { [side]: cPrototype };
        }

        return ['right', 'left'].reduce((carry, side) => ({
            ...carry,
            [side]: store.getters['lensOrder/getPrototype'](side),
        }), {});
    });

    const previousPrototype = computed(() =>
    {
        const cSide = toValue(props.side);
        const prototypeCode = toValue(lensOrders)
            .find(lensOrder => lensOrder.article_data.LENS_SIDE === cSide)
            ?.article_data
            .PROTOTYPE_CODE;

        return store.getters['prototypes/getByCode'](prototypeCode);
    });

    const previousPrototypes = computed(() =>
    {
        const cSide = toValue(props.side);
        if(cSide !== 'both')
        {
            return { [cSide]: previousPrototype };
        }

        return ['right', 'left'].reduce((carry, side) => ({
            ...carry,
            [side]: store.getters['prototypes/getByCode'](
                lensOrders.value.find(lensOrder => lensOrder.article_data.LENS_SIDE === side)
                    ?.article_data
                    .PROTOTYPE_CODE
            ),
        }), {});
    });

    const patients = computed(() =>
    {
        return store.state.patients.all;
    });

    const filteredPatients = computed(() =>
    {
        // Lowercase search string for case-insensitive comparison
        const searchString = data.patientReference1.value.toLowerCase();

        // Retrieve patient references containing the search string
        const filteredPatients = toValue(patients).filter(p => p.toLowerCase().includes(searchString));

        // Sort them in the following order:
        // 1. References starting with the search string
        // 2. References containing the search string elsewhere
        const bucketStartingWithSearchString = filteredPatients.filter(p => p.toLowerCase().startsWith(searchString));
        const bucketContainingSearchString = filteredPatients.filter(p =>
        {
            p = p.toLowerCase();

            return !p.startsWith(searchString) && p.includes(searchString);
        });

        return [
            ...bucketStartingWithSearchString,
            ...bucketContainingSearchString,
        ];
    });


    // ------------------------------------------------------------ WATCHERS

    /**
     * When the patient reference changes, update its value in the store.
     */
    watch(data.patientReference1, newPatientReference =>
    {
        const prototypeValueData = {
            paramCode:  'PATIENT_REFERENCE1',
            paramValue: newPatientReference,
        };

        const cSide = toValue(props.side);
        if(['right', 'left'].includes(cSide))
        {
            prototypeValueData.side = cSide;
        }
        else
        {
            // Do nothing.
            // The mutation `lensOrder/setPrototypeValue` updates both sides
            // if the `side` parameter is empty.
        }

        store.commit('lensOrder/setPrototypeValue', prototypeValueData);
    });


    // ------------------------------------------------------------ METHODS

    function initialize()
    {
        const cSide = toValue(props.side);

        // Load patient reference from prototype
        const loadFromSide = cSide === 'both' ? 'left' : cSide;
        data.patientReference1.value = store.getters['lensOrder/getPrototype'](loadFromSide)?.getValue('PATIENT_REFERENCE1') ?? '';

        // Load list of existing patients
        if(!toValue(patients).length)
        {
            store.dispatch('patients/fetchAll')
                .then(patients =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // todo: handle error
                });
        }
    }

    function onFocus()
    {
        data.isFocused.value = true;
    }

    function onBlur()
    {
        data.isFocused.value = false;
        data.isTouched.value = true;
    }

    function selectPatient(patientRef, limit = 2)
    {
        data.patientReference1.value = patientRef;

        // Load last lens ordered with the selected reference
        const url = '/api/order/search/entity/:eid/:offset/:limit?patient_reference1=%s'
            .replace(':eid', store.state.account.cEntity.id)
            .replace(':offset', '0')
            .replace(':limit', limit)
            .replace('%s', encodeURIComponent(patientRef));

        axios.get(url)
            .then(response =>
            {
                lensOrders.value = response.data;
                if(!lensOrders.value.length)
                {
                    console.log(`No lens orders found for patient reference "${patientRef}"`);

                    return;
                }

                onLensOrdersReceived();
            });
        // todo: handle errors
    }

    function onLensOrdersReceived()
    {
        // If the lens has the same prototype as the current one, switch to a reorder.
        // If prototypes differ, ask the user which protoypes to use.

        const loum = new LensOrderUrlManager(route);
        const cSide = toValue(props.side);

        if(!['right', 'left', 'both'].includes(cSide))
        {
            throw new TypeError(`Unsupported lens side: "${cSide}"`);
        }

        const toReorder = [];
        const toConfirm = [];

        const sides = cSide === 'both' ? ['right', 'left'] : [cSide];
        for(const cSide of sides)
        {
            const receivedLens = lensOrders.value.find(lensOrder => lensOrder.article_data.LENS_SIDE === cSide);
            if(!receivedLens)
            {
                console.log(`No lens received for side "${cSide}".`);

                return;
            }

            // Compare prototypes
            const pushOrUnshift = cSide === 'left' ? 'push' : 'unshift';
            if(receivedLens.article_data.PROTOTYPE_CODE === loum.getPrototypeCode(cSide))
            {
                // The lens's prototype matches the selected one => reorder the lens
                toReorder[pushOrUnshift](receivedLens);
            }
            else
            {
                // The prototypes don't match => confirm which one to reorder
                toConfirm[pushOrUnshift](receivedLens);
            }
        }

        // If there's nothing to confirm, reorder the lenses!
        if(!toConfirm.length)
        {
            // Let the user confirm whether they want to reorder the last lenses
            reorderConfirmationModalData.value = toReorder;
            reorderConfirmationModalOpen.value = true;
        }
        else
        {
            // Delegate the rest to the confirmation modal
            prototypeSelectionModalOpen.value = true;
        }
    }

    /**
     * Reorder lenses, as-is or with a custom prototype.
     *
     * @param {Object[]} orders
     * @param {string}   orders[].reference
     * @param {Object}   orders[].article_data
     * @param {string}   orders[].article_data.LENS_SIDE
     * @param {string}   [orders[].article_data.PROTOTYPE_CODE]
     */
    function reorder(orders, resetFittings = true)
    {
        if(orders.length < 1 || orders.length > 2)
        {
            throw new Error(`Can only reorder 1 or 2 lenses at a time; got ${orders.length}`);
        }

        // The purpose of this code is to build a URL as follows.
        // The "/prototype-code/PROTOTYPE" part is only used if there is a prototype code in the order object.
        //
        // -> /LANGUAGE/ENTITY_HASH_ID/order/lenses/right/order-reference/12345678/left/order-reference/87654321
        // -> /LANGUAGE/ENTITY_HASH_ID/order/lenses/left/order-reference/87654321
        // -> /LANGUAGE/ENTITY_HASH_ID/order/lenses/right/order-reference/12345678
        // -> /LANGUAGE/ENTITY_HASH_ID/order/lenses/right/order-reference/12345678/prototype-code/PROTOTYPE/left/order-reference/87654321/prototype-code/PROTOTYPE
        // -> /LANGUAGE/ENTITY_HASH_ID/order/lenses/left/order-reference/87654321/prototype-code/PROTOTYPE
        // -> /LANGUAGE/ENTITY_HASH_ID/order/lenses/right/order-reference/12345678/prototype-code/PROTOTYPE
        let path = `/${route.params.lang}/${route.params.entity_id}/order/lenses/`;
        const parts = [];
        for(const order of orders)
        {
            const side = order.article_data.LENS_SIDE;
            if(!['right', 'left'].includes(side))
            {
                continue;
            }

            if(side === 'left')
            {
                const leftLensParts = [`left/order-reference/${order.reference}`];
                if(order.article_data.PROTOTYPE_CODE)
                {
                    leftLensParts.push(`prototype-code/${order.article_data.PROTOTYPE_CODE}`);
                }

                parts.push(...leftLensParts);
            }
            else if(side === 'right')
            {
                const rightLensParts = [`right/order-reference/${order.reference}`];
                if(order.article_data.PROTOTYPE_CODE)
                {
                    rightLensParts.push(`prototype-code/${order.article_data.PROTOTYPE_CODE}`);
                }

                parts.unshift(...rightLensParts);
            }
        }

        path += parts.join('/');

        // HACK: Reset the `initialized` status of the prototypes before redirecting.
        // This is useful to force re-templating with the previous values.
        if(resetFittings)
        {
            store.dispatch('lensOrder/resetFitting');
        }
        // @HACK END

        router.push({ path });
    }

    function reorderConfirmationModal_OnCancel()
    {
        reorderConfirmationModalData.value = [];
        reorderConfirmationModalOpen.value = false;
    }

    function reorderConfirmationModal_OnValidate()
    {
        reorder(toValue(reorderConfirmationModalData));
    }


    // ------------------------------------------------------------ EXPORT

    return {
        // Constants
        // ...

        // Data
        reorderConfirmationModalData,
        reorderConfirmationModalOpen,
        prototypeSelectionModalOpen,
        lensOrders,

        // Computed
        tabIndex,
        cPrototype,
        cPrototypes,
        previousPrototype,
        previousPrototypes,
        patients,
        filteredPatients,

        // Methods
        initialize,
        onFocus,
        onBlur,
        selectPatient,
        reorder,
        reorderConfirmationModal_OnCancel,
        reorderConfirmationModal_OnValidate,
    };
};
