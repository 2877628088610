<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="flex px-4">
        <!-- First Column: Lens Parameters -->
        <div class="sl-basket-line-details-col">
            <div class="sl-basket-line-details__title">
                {{ $t('order_table.details.section.lens_parameters.title') }}
            </div>

            <!-- Prototype -->
            <div class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    -&nbsp;{{ $t(`parameters.PROTOTYPE_CODE.label`) }}:
                </div>

                <div class="sl-basket-line-details-line__value" :title="prototypeCode">
                    {{ prototypeLabel }}
                </div>
            </div>

            <div v-for="prop in article_data" :key="prop.parameterCode" class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    -&nbsp;{{ getLabelByParameterCode(prop.parameterCode) }}<span v-if="getUnitByParameterCode(prop.parameterCode)" class="sl-basket-line-details-line__unit">[&nbsp;{{ getUnitByParameterCode(prop.parameterCode) }}&nbsp;]</span>:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-if="prop.parameterValue != null" class="sl-basket-line-details-line__value" v-html="$pf(prop.parameterValue, prop.parameterCode, 'value')"></div>

                <div v-else class="sl-basket-line-details-line__value">
                    -
                </div>

                <div
                    v-if="getHelp(prop.parameterCode, prop.parameterValue)"
                    class="ml-1 text-primary-400 text-xs"
                >
                    ({{ getHelp(prop.parameterCode, prop.parameterValue) }})
                </div>
            </div>

            <!-- Quantity -->
            <div class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    -&nbsp;{{ $t('parameters.quantity.label') }}:
                </div>

                <div class="sl-basket-line-details-line__value">
                    {{ basketLine.quantity }}
                </div>
            </div>

            <!-- Previous BasketLine Reference -->
            <div v-if="basketLine.previous_order_reference" class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    -&nbsp;{{ $t("order.previous_order_reference.label") }}:
                </div>

                <div class="sl-basket-line-details-line__value">
                    {{ basketLine.previous_order_reference }}
                </div>
            </div>
        </div>

        <!-- Second Column: Logistics / Return / Extra -->
        <div class="sl-basket-line-details-col">
            <!-- Logistics  -->
            <div class="sl-basket-line-details__title">
                {{ $t('order_table.details.section.delivery.title') }}
            </div>

            <!-- Delivery Address -->
            <div v-if="deliveryAddress" class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    {{ $t('common.address.delivery.label') }}:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-basket-line-details-line__address" v-html="$f(deliveryAddress, 'address')"></div>
            </div>

            <!-- Delivery Options -->
            <div v-for="prop in logistics_data" :key="prop.parameterCode" class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    -&nbsp;{{ $t(`parameters.${prop.parameterCode}.label`) }}:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-basket-line-details-line__value" v-html="$pf(prop.parameterValue, prop.parameterCode, 'value')"></div>
            </div>

            <!-- Return  -->
            <div class="sl-basket-line-details__title">
                {{ $t('order_table.details.section.return.title') }}
            </div>

            <div v-for="prop in return_data" :key="prop.parameterCode" class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    -&nbsp;{{ $t(`parameters.${prop.parameterCode}.label`) }}:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-basket-line-details-line__value" v-html="$pf(prop.parameterValue, prop.parameterCode, 'value')"></div>
            </div>

            <!-- Extra  -->
            <div class="sl-basket-line-details__title">
                {{ $t('order_table.details.section.complements.title') }}
            </div>

            <div class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    {{ $t('order_table.details.section.complements.placed_by_mean.label') }}:
                </div>

                <div class="sl-basket-line-details-line__value">
                    {{ $pf(basketLine.extra.placed_by_mean, 'placed_by_mean', 'value') }}
                </div>
            </div>

            <div v-if="basketLine.extra.ext_ref" class="sl-basket-line-details-line">
                <div class="sl-basket-line-details-line__label">
                    {{ $t('parameters.ext_ref.label') }} :
                </div>

                <div class="sl-basket-line-details-line__value">
                    {{ basketLine.extra.ext_ref }}
                </div>
            </div>
        </div>

        <!-- Third Column: Price Details -->
        <div class="sl-basket-line-details-col">
            <div class="sl-basket-line-details__title">
                {{ $t('order_table.details.section.price_details.title') }}
            </div>

            <div class="sl-basket-line-price-line">
                <div class="sl-basket-line-price-line__label">
                    {{ getLabel(basketLine) }}
                </div>

                <div class="sl-basket-line-price-line__price">
                    <template v-if="!orderIsMoreExpensiveThanCatalogPrice">
                        {{ basketLine.currency_code }}&nbsp;{{ $f(basketLine.price, 'decimal:2') }}
                    </template>
                </div>
            </div>

            <div v-for="supplement in basketLine.supplements" :key="supplement.code" class="sl-basket-line-price-line pl-4">
                <div class="sl-basket-line-price-line__label">
                    +&nbsp;{{ supplement.label || supplement.code }}

                    <span v-if="supplement.quantity > 1" class="text-gray-400">
                        &times;
                        {{ supplement.quantity }}
                    </span>
                </div>

                <div class="sl-basket-line-price-line__price">
                    <template v-if="!orderIsMoreExpensiveThanCatalogPrice">
                        {{ supplement.currency_code }}&nbsp;{{ $f(supplement.price * supplement.quantity, 'decimal:2') }}
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { merge, omit } from 'lodash-es';
import {
    getLabel,
    getLabelByParameterCode,
    getUnitByParameterCode,
    getHelp,
} from '@/shared/BasketTableSharedObjects';

export default
{
    name: 'LensBasketLineDetails',

    props:
    {
        basketLine:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        prototypeCode()
        {
            return this.basketLine.article_data['PROTOTYPE_CODE'];
        },

        prototypeLabel()
        {
            return this.$store.state.prototypes.prototypes.find(p => p.code === this.prototypeCode).label;
        },

        article_data()
        {
            let data = merge({}, this.basketLine.article_data, this.basketLine.data);

            // Remove some values.
            data = omit(data, [
                'LENS_SIDE',
                'PROTOTYPE_CODE',
                'PATIENT_ID',
                'PATIENT_REFERENCE1',
                'PROTOTYPE_VERSION',
            ]);

            // Order values
            return this.collection(data);
        },

        logistics_data()
        {
            return this.collection(this.basketLine.logistics_data);
        },

        return_data()
        {
            return this.collection(this.basketLine.return_data);
        },

        deliveryAddress()
        {
            if(this.basketLine.delivery_to == this.basketLine.order_by)
            {
                return this.cEntity.addresses.find(a => a.type === 'DELIVERY') || null;
            }

            return null;
        },

        cEntity()
        {
            return this.$store.state.account.cEntity;
        },

        total()
        {
            return (this.basketLine.supplements ?? []).reduce(
                (carry, supplement) => carry + supplement.price * supplement.quantity,
                this.basketLine.price
            );
        },

        orderIsMoreExpensiveThanCatalogPrice()
        {
            if(this.basketLine.special_price === null)
                return false;

            return this.basketLine.special_price > this.total;
        },
    },

    methods:
    {
        collection(data)
        {
            const collection = [];

            // Order
            for(const key in data)
            {
                if(parseInt(this.$store.getters['parameters/getProperty'](this.prototypeCode, key, 'has_access')))
                {
                    collection.push({
                        parameterCode:  key,
                        parameterValue: data[key],
                        parameterOrder: parseInt(this.$store.getters['parameters/getProperty'](this.prototypeCode, key, 'sort_order')),
                    });
                }
            }

            // Order values
            return collection.sort((a, b) => a.parameterOrder - b.parameterOrder);
        },

        getLabel,
        getLabelByParameterCode,
        getUnitByParameterCode,
        getHelp,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-basket-line-details-col
{
    @apply w-1/3 pb-3;
}

.sl-basket-line-details__title
{
    @apply text-gray-700 text-sm mt-4 mb-1;
}

.sl-basket-line-details-line
{
    @apply flex flex-wrap;
}

.sl-basket-line-details-line__label
{
    @apply text-xs text-gray-500;
}

.sl-basket-line-details-line__unit
{
    @apply align-middle ml-1 mr-0.5 text-[8px] text-gray-400;
}

.sl-basket-line-details-line__value
{
    @apply pl-2 text-xs text-primary-500;
}

.sl-basket-line-details-line__address
{
    @apply w-full my-1 text-xs text-primary-500 leading-4;
}

.sl-basket-line-price-line
{
    @apply flex mt-2 py-2 border-t border-gray-200;
}

.sl-basket-line-price-line:last-child
{
    @apply border-b;
}

.sl-basket-line-price-line__label
{
    @apply grow text-xs text-gray-500;
}

.sl-basket-line-price-line__price
{
    width: 100px;
    @apply text-xs text-primary-500 text-right;
}
</style>
