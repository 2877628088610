<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<template>
    <div class="sl-calculators space-y-2">
        <eyeglass-power-conversion @close="$emit('close')" />

        <power-adjustment v-if="adaptingRgp" @close="$emit('close')" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import EyeglassPowerConversion from './calculators/EyeglassPowerConversion.vue';
import PowerAdjustment         from './calculators/PowerAdjustment.vue';

export default
{
    name: 'Calculators',

    components:
    {
        EyeglassPowerConversion,
        PowerAdjustment,
    },

    emits: ['close'],

    computed:
    {
        rightPrototype()
        {
            const prototypeCode = this.$store.getters['lensOrder/getPrototype']('right')?.code;
            if(!prototypeCode)
            {
                return null;
            }

            return this.$store.state.prototypes.prototypes.find(p => p.code === prototypeCode);
        },

        leftPrototype()
        {
            const prototypeCode = this.$store.getters['lensOrder/getPrototype']('left')?.code;
            if(!prototypeCode)
            {
                return null;
            }

            return this.$store.state.prototypes.prototypes.find(p => p.code === prototypeCode);
        },

        /**
         * Is the user adapting an RGP lens on either side?
         * @returns {Boolean}
         */
        adaptingRgp()
        {
            return this.rightPrototype?.tags.some(t => t.code === 'RGP')
                || this.leftPrototype?.tags.some(t => t.code === 'RGP');
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
