import Fitting from '@/models/Fitting';

export default class CreateFittingIfNotExists
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;

        // If URL has nothing for given site (no reference, SN or prototype code) => reset side.
        if(lensOrderUrlManager.hasPrototypeCode(this.side))
        {
            const fitting = this.v.$store.getters['lensOrder/getFitting'](this.side);

            // If there is no fitting object for this side, create one.
            if(!fitting)
            {
                // Mutation
                this.v.$store.commit('lensOrder/setFitting', {
                    side:    this.side,
                    fitting: new Fitting(this.v.$store),
                });

                console.log(`Fitting instance was created on ${this.side} side.`);
            }
        }

        return true;
    }
}
