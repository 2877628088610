// =============
// Basket Module
// =============

import { orderBy } from 'lodash-es';
import qs          from 'qs';
import axios       from '@/axios';
import BasketLine  from '@/models/BasketLine';

export function setupBasketModule(i18n)
{
    return {
        namespaced: true,

        state()
        {
            return {
                lines:       [],
                lastOrders:  [],
                lastOptions: null,
            };
        },

        getters:
        {
            getLinesByArticleCode: state => articleCode => state.lines.filter(l => l.article_code === articleCode),

            getLineByPairId: state => lensPairId =>
            {
                // Build other side lens pair ID.
                const letter = lensPairId.endsWith('A') ? 'B' : 'A';
                const otherSideLensPairId = lensPairId.slice(0, -1) + letter;

                // Look for other side lens.
                const line = state.lines.find(line => line.article_data?.LENS_PAIR_ID == otherSideLensPairId);
                if(!line)
                {
                    return null;
                }

                return new BasketLine(line);
            },

            getById: state => id =>
            {
                const line = state.lines.find(line => line.id == id);
                if(!line)
                {
                    return null;
                }

                return new BasketLine(line);
            },
        },

        mutations:
        {
            /**
             *
             * @param {Object} state
             */
            setLines(state, lines)
            {
                state.lines = orderBy(lines, ['created_at'], ['desc']);
            },

            reset(state, lineIds = [])
            {
                if(lineIds.length)
                {
                    let lines = [];

                    for(let i in state.lines)
                    {
                        if(!lineIds.includes(state.lines[i].id))
                        {
                            lines.push(state.lines[i]);
                        }
                    }

                    state.lines = lines;
                }
                else
                {
                    state.lines = [];
                }
            },

            setLastOrders(state, orders)
            {
                state.lastOrders = orders;
            },

            setLastOptions(state, options)
            {
                state.lastOptions = options;
            },
        },

        actions:
        {
            /**
             * Load basket lines from the server.
             *
             * @param {Object} param
             */
            load({ commit, rootState })
            {
                return new Promise((resolve, reject) =>
                {
                    const cEntityId = rootState.account.cEntity.id;
                    axios.get(`/api/basket/${cEntityId}`)
                        .then(response =>
                        {
                            commit('setLines', response.data);
                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },

            /**
             * Save one or multiple lines to the basket.
             *
             * @param {Object} commit
             */
            saveLines({ commit, dispatch, rootState }, lines)
            {
                return new Promise((resolve, reject) =>
                {
                    const cEntityId = rootState.account.cEntity.id;

                    if(!Array.isArray(lines))
                    {
                        lines = [lines];
                    }

                    for(const i in lines)
                    {
                        const line = lines[i]; // shortcut

                        // Format supplements (if any)
                        if(Array.isArray(line.supplements))
                        {
                            line.supplements = line.supplements.map(({ quantity, code, article_code }) =>
                            {
                                if(!article_code && !code)
                                {
                                    throw new Error('Supplement must have an "article_code" or a "code" property');
                                }

                                return {
                                    quantity,
                                    article_code: article_code || code,
                                };
                            });
                        }
                    }

                    axios.post(`/api/basket/${cEntityId}`, { lines })
                        .then(response =>
                        {
                            commit('setLines', response.data);

                            if(response.meta?.boxes_were_compacted)
                            {
                                // Add a notification indicating that the basket was compacted automatically
                                dispatch('notifications/addBandNotification', {
                                    id:          'BASKET_COMPACTED',
                                    theme:       'warn',
                                    title:       'basket.notice.compacted.title',
                                    text:        'basket.notice.compacted.text',
                                    dismissible: true,
                                }, { root: true });
                            }
                            else
                            {
                                // Remove the notification (if any)
                                dispatch('notifications/removeBandNotification', 'BASKET_COMPACTED', { root: true });
                            }

                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });

            },

            /**
             * Delete a line from the basket.
             *
             * @param {Object} commit
             */
            deleteLine({ commit, dispatch, rootState }, lineId)
            {
                return new Promise((resolve, reject) =>
                {
                    return axios.delete(`/api/basket/${rootState.account.cEntity.id}/${lineId}`)
                        .then(response =>
                        {
                            commit('setLines', response.data);
                            dispatch('notifications/removeBandNotification', 'BASKET_COMPACTED', { root: true });

                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);

                            reject(error);
                        });
                });
            },

            /**
             * Empty the basket.
             *
             * @param {Object} commit
             */
            deleteAllLines({ commit, dispatch, rootState })
            {
                return new Promise((resolve, reject) =>
                {
                    return axios.delete(`/api/basket/${rootState.account.cEntity.id}`)
                        .then(response =>
                        {
                            commit('reset');
                            dispatch('notifications/removeBandNotification', 'BASKET_COMPACTED', { root: true });

                            resolve(response.data);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        });
                });
            },

            /**
             * Place the order.
             */
            place({ commit, dispatch, rootState }, { options, selectedBasketLineIds })
            {
                return new Promise((resolve, reject) =>
                {
                    let url = '/api/order/:eid'
                        .replace(':eid', rootState.account.cEntity.id);
                    const data = { options };

                    if(selectedBasketLineIds)
                    {
                        url += '?' + qs.stringify({ basket_line_ids: selectedBasketLineIds });
                    }

                    commit('overlay/setText', i18n.global.t('overlay.placing'), { root: true });
                    commit('overlay/setVisibility', true, { root: true });

                    axios.post(url, data)
                        .then(response =>
                        {
                            commit('reset', selectedBasketLineIds);
                            commit('setLastOrders', response.data);
                            commit('setLastOptions', options);
                            dispatch('notifications/removeBandNotification', 'BASKET_COMPACTED', { root: true });

                            resolve(response);
                        })
                        .catch(error =>
                        {
                            console.log(error);
                            reject(error);
                        })
                        .finally(() =>
                        {
                            commit('overlay/setVisibility', false, { root: true });
                        });
                });
            },
        },
    };
};
