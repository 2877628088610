import Objects from '@/utils/Objects';

/**
 * @typedef {import('@/views/order/lenses/fields/custom/PlotGraphButton.vue').Point} Point
 */

export default class AbstractSurfaceFilter
{
    zone = '';

    constructor(options = {})
    {
        this.zone = options.zone;
    }

    /**
     * Run the filter.
     *
     * @param {Point[]} points
     * @returns {Point[]}
     */
    run(points)
    {
        return points;
    }
}
