export default class UpdateOrders
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const orders = [];
        const rightFitting = this.v.$store.getters['lensOrder/getFitting']('right');
        const leftFitting  = this.v.$store.getters['lensOrder/getFitting']('left');

        this.v.$store.commit('overlay/setText', this.v.$t('overlay.updating_orders'));

        if(rightFitting)
        {
            orders.push(rightFitting.toOrder());
        }

        if(leftFitting)
        {
            orders.push(leftFitting.toOrder());
        }

        return this.v.$store.dispatch('orders/update', { orders })
            .then(response =>
            {
                console.log('These orders have been updated:', orders);
                return Promise.resolve(response);
            });
    }
}
