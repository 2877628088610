// ============
// Forms Module
// ============

import axios from '@/axios';

export default
{
    namespaced: true,


    // ------------------------------------------------------------ STATE

    state()
    {
        return {
            ofg: [],
        };
    },


    // ------------------------------------------------------------ GETTERS

    getters:
    {
        getOfgByCode: state => code => state.ofg.find(form => form.code === code),
    },


    // ------------------------------------------------------------ MUTATIONS

    mutations:
    {
        setOfg(state, ofg)
        {
            state.ofg = ofg;
        },
    },


    // ------------------------------------------------------------ ACTIONS

    actions:
    {
        fetchAllOfg({ commit })
        {
            return axios.get('/api/form/type/OFG')
                .then(response =>
                {
                    commit('setOfg', response.data);
                });
        },
    },
};
