/**
 * Once both prototypes have been templated, loop through parameters
 * and search for grouped parameters. Then, check that parameter is
 * grouped on both sides and that value is `null` on one side but not
 * on the other. Finally, replace `null` value by the value from the other side.
 * Note: since grouping keys are the same on both sides, possible values are
 * necessarily also the same.
 */
export default class CompleteGroupedParameterValues
{
    /**
     * Vue Object
     */
    v = null;

    constructor(v)
    {
        this.v = v;
    }

    async handle()
    {
        const rightPrototype = this.v.$store.getters['lensOrder/getPrototype']('right');
        const leftPrototype  = this.v.$store.getters['lensOrder/getPrototype']('left');
        if(!rightPrototype || !leftPrototype)
        {
            console.log('Less than two sides => no value to complete');
            return true;
        }

        rightPrototype.getParameters().forEach((rightParameter, code) =>
        {
            if(['Y', 'F'].includes(rightParameter.is_grouped))
            {
                const leftParameter = leftPrototype.getParameterByCode(code);
                if(leftParameter && ['Y', 'F'].includes(leftParameter.is_grouped))
                {
                    if(rightParameter.grouping_key == leftParameter.grouping_key)
                    {
                        const rightValue = rightParameter.getValue();
                        const leftValue = leftParameter.getValue();

                        if(rightValue === null && leftValue !== null)
                        {
                            rightParameter.setValue(leftValue);
                        }
                        else if(leftValue === null && rightValue !== null)
                        {
                            leftParameter.setValue(rightValue);
                        }
                    }
                }
            }
        });

        console.log('Grouped parameter values have been copied from right to left');

        return true;
    }
}
